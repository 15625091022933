import React, { useState } from 'react';
import {
  Box, Button, Progress, Heading, Text, VStack, Icon, useToast, Flex,
  Card, CardHeader, CardBody, CardFooter, SimpleGrid
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { FaUser, FaComments } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import ThirtySecondBoost from './ThirtySecondBoost';

const WelcomeComponent = () => {
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(33);
  const [category, setCategory] = useState('');
  const [selectedChallenge, setSelectedChallenge] = useState('');
  const toast = useToast();
  const navigate = useNavigate();

  const businessCategories = [
    { name: "Hair Stylists", icon: FaUser },
    { name: "Barbers", icon: FaUser },
    { name: "Nail Technicians", icon: FaUser },
    { name: "Spas and Wellness Professionals", icon: FaUser },
    { name: "Skin Care Specialists", icon: FaUser },
    { name: "Makeup and Cosmetic Artists", icon: FaUser },
    { name: "Beauty Product Suppliers", icon: FaUser },
    { name: "Aesthetic and Cosmetic Specialists", icon: FaUser },
    { name: "Beauty and Cosmetology Educators", icon: FaUser },
    { name: "Tanning Specialists", icon: FaUser },
    { name: "Fragrance Experts", icon: FaUser },
    { name: "Beauty Subscription Curators and Online Retailers", icon: FaUser },
    { name: "Mobile Beauty Service Providers and Freelancers", icon: FaUser }
  ];

  const challengeOptions = [
    "Growing my client base and bookings",
    "Maximizing my profitability",
    "Staying on top of industry trends and skills"
  ];

  const nextStep = async () => {
    if (step === 1 && !category) {
      toast({
        title: "Selection Required",
        description: "Please select a business category before proceeding.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (step === 1) {
      try {
        const response = await fetch('https://sbscoaches.com/api/save-business-category', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ businessCategory: category }),
          credentials: 'include',
        });

        if (response.ok) {
          toast({
            title: "Success",
            description: "Your business category has been saved.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(step + 1);
          setProgress(66);
        } else {
          throw new Error('Failed to save business category');
        }
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to save business category. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      setStep(step + 1);
      setProgress(100);
    }
  };

  const handleSaveAndContinue = async () => {
    if (!selectedChallenge) {
      toast({
        title: "Selection Required",
        description: "Please select a challenge before proceeding.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await fetch('https://sbscoaches.com/api/save-challenge', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ challenge: selectedChallenge }),
        credentials: 'include',
      });

      if (response.ok) {
        toast({
          title: "Success",
          description: "Your selected challenge has been saved.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setStep(3);
      } else {
        throw new Error('Failed to save selected challenge');
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to save selected challenge. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleBoostComplete = () => {
    navigate('/user-profile');
  };

  return (
    <Box minH="100vh" bg="slate.50" p={4}>
      <Card maxW="800px" mx="auto" boxShadow="md">
        <CardHeader>
          <Flex align="center" justify="center" flexDirection="column">
            <Icon as={FaComments} w={10} h={10} color="teal.500" mb={2} />
            <Heading size="lg" color="slate.800" textAlign="center">Welcome to SBS Coaches</Heading>
          </Flex>
          <Progress value={progress} colorScheme="teal" size="sm" mt={4} />
        </CardHeader>

        <CardBody>
          {step === 1 && (
            <VStack spacing={4}>
              <Text fontSize="md" color="slate.800" textAlign="center">What type of business do you have?</Text>
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={3} w="full">
                {businessCategories.map((cat) => (
                  <Button
                    key={cat.name}
                    variant="outline"
                    colorScheme="teal"
                    size="lg"
                    height="auto"
                    py={3}
                    px={4}
                    onClick={() => setCategory(cat.name)}
                    isActive={category === cat.name}
                    _active={{ bg: "teal.50", borderColor: "teal.500" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    textAlign="left"
                    whiteSpace="normal"
                    wordBreak="break-word"
                  >
                    <Icon as={cat.icon} boxSize={5} mr={3} flexShrink={0} />
                    <Text fontSize="sm" fontWeight="bold">
                      {cat.name}
                    </Text>
                  </Button>
                ))}
              </SimpleGrid>
            </VStack>
          )}

          {step === 2 && (
            <VStack spacing={4}>
              <Text fontSize="md" color="slate.800" textAlign="center">What's your biggest focus?</Text>
              <VStack spacing={3} w="full">
                {challengeOptions.map((challenge) => (
                  <Button
                    key={challenge}
                    variant="outline"
                    colorScheme="teal"
                    size="lg"
                    height="auto"
                    py={3}
                    px={4}
                    w="full"
                    onClick={() => setSelectedChallenge(challenge)}
                    isActive={selectedChallenge === challenge}
                    _active={{ bg: "teal.50", borderColor: "teal.500" }}
                    textAlign="left"
                    whiteSpace="normal"
                  >
                    <Text fontSize="sm">{challenge}</Text>
                  </Button>
                ))}
              </VStack>
            </VStack>
          )}

          {step === 3 && (
            <ThirtySecondBoost
              businessCategory={category}
              onComplete={handleBoostComplete}
            />
          )}
        </CardBody>

        <CardFooter>
          {step < 3 && (
            <Button
              onClick={step === 1 ? nextStep : handleSaveAndContinue}
              w="full"
              colorScheme="teal"
              rightIcon={<ChevronRightIcon />}
              size="lg"
            >
              {step === 1 ? "Next Step" : "Start 30-Second Boost"}
            </Button>
          )}
        </CardFooter>
      </Card>
    </Box>
  );
};

export default WelcomeComponent;
