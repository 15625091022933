import React, { useState } from 'react';
import {
  Box, Button, Progress, Heading, Text, VStack, HStack, Grid,
  Card, CardHeader, CardBody, Badge, Tabs, TabList, TabPanels, Tab, TabPanel
} from '@chakra-ui/react';
import { CheckCircleIcon, LockIcon, ChevronRightIcon, StarIcon, ExternalLinkIcon } from '@chakra-ui/icons';

const challengeData = [
  { day: 1, title: "Post a selfie at your workspace", completed: true },
  { day: 2, title: "Share your favorite styling tip", completed: true },
  { day: 3, title: "Post a before/after transformation", completed: false },
  { day: 4, title: "Showcase your favorite product", completed: false },
  { day: 5, title: "Share a client testimonial", completed: false },
  { day: 6, title: "Demonstrate a trending technique", completed: false },
  { day: 7, title: "Reflect on your week's growth", completed: false },
];

const ChallengeDay = ({ day, title, completed, active, onComplete }) => (
  <Card mb={4} borderWidth={active ? 2 : 1} borderColor={active ? 'teal.600' : 'gray.200'}>
    <CardHeader display="flex" justifyContent="space-between" alignItems="center" pb={2}>
      <Heading size="sm">Day {day}</Heading>
      {completed ? (
        <Badge colorScheme="teal"><CheckCircleIcon mr={1} /> Completed</Badge>
      ) : active ? (
        <Badge colorScheme="yellow">Active</Badge>
      ) : (
        <LockIcon color="gray.400" />
      )}
    </CardHeader>
    <CardBody>
      <Text fontSize="sm" color="gray.700" mb={2}>{title}</Text>
      {active && !completed && (
        <Button onClick={onComplete} colorScheme="teal" width="full">
          Complete Challenge
        </Button>
      )}
      {completed && (
        <Button variant="outline" colorScheme="teal" width="full">
          <ExternalLinkIcon mr={2} /> Share Result
        </Button>
      )}
    </CardBody>
  </Card>
);

const SevenDayChallenge = () => {
  const [activeDay, setActiveDay] = useState(3);
  const progress = (challengeData.filter(day => day.completed).length / challengeData.length) * 100;

  return (
    <Box maxWidth="4xl" margin="auto" p={4}>
      <Grid templateColumns={{ base: "1fr", lg: "2fr 1fr" }} gap={8}>
        <VStack align="stretch">
          <Card mb={6} boxShadow="lg">
            <CardHeader bgColor="teal.600" color="white">
              <Heading size="md" display="flex" justifyContent="space-between" alignItems="center">
                <span>7-Day Style Challenge</span>
                <StarIcon color="yellow.300" />
              </Heading>
            </CardHeader>
            <CardBody p={6}>
              <Progress value={progress} colorScheme="teal" mb={4} />
              <Text fontSize="sm" color="gray.600" mb={4}>
                {challengeData.filter(day => day.completed).length} of 7 challenges completed
              </Text>
              <HStack justifyContent="space-between">
                <Text fontSize="sm" fontWeight="medium" color="gray.700">Current Streak: 2 days</Text>
                <Button variant="outline" colorScheme="teal" size="sm">
                  <ExternalLinkIcon mr={2} /> Share Progress
                </Button>
              </HStack>
            </CardBody>
          </Card>

          <Tabs>
            <TabList>
              <Tab>Daily View</Tab>
              <Tab>Weekly Overview</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {challengeData.map((challenge) => (
                  <ChallengeDay
                    key={challenge.day}
                    {...challenge}
                    active={challenge.day === activeDay}
                    onComplete={() => setActiveDay(activeDay + 1)}
                  />
                ))}
              </TabPanel>
              <TabPanel>
                <Card p={6}>
                  <Grid templateColumns="repeat(7, 1fr)" gap={2}>
                    {challengeData.map((challenge) => (
                      <VStack key={challenge.day}>
                        <Box
                          rounded="full"
                          w={10}
                          h={10}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          bgColor={challenge.completed ? 'teal.600' : 'gray.200'}
                          color={challenge.completed ? 'white' : 'gray.600'}
                        >
                          {challenge.day}
                        </Box>
                        <Text fontSize="xs" color="gray.600">
                          {challenge.completed ? 'Done' : 'Todo'}
                        </Text>
                      </VStack>
                    ))}
                  </Grid>
                </Card>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>

        <Card boxShadow="lg">
          <CardHeader bgColor="gray.700" color="white">
            <Heading size="md">Challenge Rewards</Heading>
          </CardHeader>
          <CardBody p={6}>
            <Text fontSize="sm" color="gray.600" mb={4}>
              Complete all 7 days to unlock exclusive stylist badge and features!
            </Text>
            <Button colorScheme="teal" width="full" rightIcon={<ChevronRightIcon />}>
              View All Rewards
            </Button>
          </CardBody>
        </Card>
      </Grid>
    </Box>
  );
};

export default SevenDayChallenge;
