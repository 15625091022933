import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Heading, Text, VStack, Spinner, Divider, List, ListItem } from '@chakra-ui/react';

const Today = () => {
  const [appointments, setAppointments] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTodaysAppointments = async () => {
      try {
        const response = await axios.get('https://sbscoaches.com/api/get-todays-appointments', {
          withCredentials: true
        });
        console.log('Appointments data:', JSON.stringify(response.data, null, 2));
        setAppointments(response.data);
      } catch (error) {
        console.error('Error fetching today\'s appointments:', error);
        setError("Unable to fetch today's appointments. If you don't have a Square account, you can create one at square.com/signup to use this part of SBS Coaches. A Square account helps manage appointments and payments. Once you have an account, connect it in the Integrations tab to view your appointments here.");
      } finally {
        setLoading(false);
      }
    };
    fetchTodaysAppointments();
  }, []);

  const formatTime = (isoString) => {
    if (!isoString) return 'N/A';
    const date = new Date(isoString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  return (
    <Box p={4}>
      <Heading size="md" mb={4}>Today's Agenda</Heading>
      {loading ? (
        <Spinner />
      ) : error ? (
        <Text color="red.500">{error}</Text>
      ) : (
        <Box>
          {appointments.length > 0 ? (
            <VStack spacing={4} align="stretch">
              {appointments.map((appointment) => (
                <Box key={appointment.id} p={4} shadow="md" borderWidth="1px" borderRadius="md">
                  <Text fontWeight="bold">{appointment.customer_name}</Text>
                  <Text>Start Time: {formatTime(appointment.start_at)}</Text>

                  <Text fontWeight="semibold" mt={2}>Services:</Text>
                  <List spacing={2}>
                    {appointment.services.map((service, index) => (
                      <ListItem key={index}>
                        {service.name} - {service.duration_minutes} minutes
                      </ListItem>
                    ))}
                  </List>

                  {appointment.weeks_since_last_appointment !== null && (
                    <Text mt={2} fontStyle="italic">
                      Weeks since last appointment: {appointment.weeks_since_last_appointment}
                    </Text>
                  )}

                  {(appointment.seller_note || appointment.booking_customer_note || appointment.client_notes) && <Divider my={2} />}

                  {appointment.seller_note && (
                    <Box mt={2}>
                      <Text fontWeight="semibold">Seller's Note:</Text>
                      <Text>{appointment.seller_note}</Text>
                    </Box>
                  )}

                 {appointment.booking_customer_note && (
                    <Box mt={2}>
                      <Text fontWeight="semibold">Booking Customer Note:</Text>
                      <Text>{appointment.booking_customer_note}</Text>
                    </Box>
                  )}

                  {appointment.client_notes && (
                    <Box mt={2}>
                      <Text fontWeight="semibold">Client Notes:</Text>
                      <Text>{appointment.client_notes}</Text>
                    </Box>
                  )}
                </Box>
              ))}
            </VStack>
          ) : (
            <Text>No appointments for today.</Text>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Today;
