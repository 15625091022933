import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Heading, Text, Button, Spinner, Flex, Icon } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { FaChartLine } from 'react-icons/fa';
import { Bar, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import ScheduleCoaching from './ScheduleCoaching';

const MonthlyCoachFeedback = () => {
  const [monthlyCoachFeedback, setMonthlyCoachFeedback] = useState('');
  const [chartData, setChartData] = useState(null);
  const [businessCategory, setBusinessCategory] = useState('');
  const [isPaid, setIsPaid] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch('https://sbscoaches.com/api/user_info', {
          credentials: 'include',
        });
        const data = await response.json();
        if (response.ok) {
          setBusinessCategory(data.business_category || '');
        } else {
          throw new Error(data.error || 'Failed to fetch user info');
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    const fetchMonthlyCoachFeedback = async () => {
      try {
        const response = await fetch('https://sbscoaches.com/api/get-monthly-coach-feedback', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ businessCategory }),
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Fetched data:', data);  // Debug logging
          setMonthlyCoachFeedback(data.feedback || '');
          setChartData(data.chartData || null);
        } else {
          const data = await response.json();
          throw new Error(data.error || 'Failed to get monthly coach feedback');
        }
      } catch (error) {
        console.error('Error getting monthly coach feedback:', error);
        setMonthlyCoachFeedback('Failed to get monthly coach feedback. Please try again.');
      }
    };

    if (businessCategory) {
      fetchMonthlyCoachFeedback();
    }
  }, [businessCategory]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get('payment') === 'success') {
      setIsPaid(true);
    }
  }, [location.search]);

  useEffect(() => {
    console.log('Chart data updated:', chartData);
  }, [chartData]);

  const handleBackToProfile = () => {
    navigate('/user-profile');
  };

  const handlePayment = () => {
    window.location.href = 'https://book.stripe.com/bIY00Ybsk7Ew14Q6ox';
  };

  const renderChart = (data, ChartComponent, label) => {
    if (!data || !data.labels || !data.datasets) {
      console.log(`Invalid data for ${label}:`, data);
      return null;
    }
    return (
      <Box mt={6} overflowX="scroll">
        <Heading as="h3" size="md" mb={2}>{label}</Heading>
        <ChartComponent data={data} />
      </Box>
    );
  };

  return (
    <Box>
      <Button
        leftIcon={<ArrowBackIcon />}
        variant="ghost"
        size="sm"
        mb={4}
        onClick={handleBackToProfile}
      >
        Back to Profile
      </Button>
      <Box textAlign="center" mb={4}>
        <Icon as={FaChartLine} w={10} h={10} mb={4} color="blue.500" />
        <Heading as="h2" size="xl" mb={4}>
          Coach, Let's Time Travel! How's My Multi-Month Performance?
        </Heading>
      </Box>
      {monthlyCoachFeedback ? (
        <>
          <Text>
            {monthlyCoachFeedback.split('\n').map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </Text>
          {chartData && (
            <>
              {renderChart({
                labels: chartData.startDates,
                datasets: [{
                  label: 'Total Service Revenue',
                  data: chartData.serviceRevenue,
                  backgroundColor: 'rgba(54, 162, 235, 0.6)',
                }]
              }, Bar, 'Service Revenue')}
              
              {renderChart({
                labels: chartData.startDates,
                datasets: [{
                  label: 'Total Retail Revenue',
                  data: chartData.retailRevenue,
                  backgroundColor: 'rgba(75, 192, 192, 0.6)',
                }]
              }, Bar, 'Retail Revenue')}
              
              {renderChart({
                labels: chartData.startDates,
                datasets: [{
                  label: 'Total Clients',
                  data: chartData.totalClients,
                  borderColor: 'rgba(153, 102, 255, 0.6)',
                  tension: 0.1,
                  fill: false,
                }]
              }, Line, 'Total Clients')}
              
              {renderChart({
                labels: chartData.startDates,
                datasets: [{
                  label: 'Average Service Ticket',
                  data: chartData.avgServiceTicket,
                  backgroundColor: 'rgba(255, 159, 64, 0.6)',
                }]
              }, Bar, 'Average Service Ticket')}
              
              {renderChart({
                labels: chartData.startDates,
                datasets: [{
                  label: 'Average Retail Ticket',
                  data: chartData.avgRetailTicket,
                  backgroundColor: 'rgba(255, 205, 86, 0.6)',
                }]
              }, Bar, 'Average Retail Ticket')}
              
              {renderChart({
                labels: chartData.startDates,
                datasets: [{
                  label: 'Retail to Service (%)',
                  data: chartData.retailToServicePercentage,
                  borderColor: 'rgba(255, 99, 132, 0.6)',
                  tension: 0.1,
                  fill: false,
                }]
              }, Line, 'Retail to Service Percentage')}
            </>
          )}
          {isPaid ? (
            <ScheduleCoaching calendlyUrl="https://calendly.com/saloncoach100/live-business-coaching-session" />
          ) : (
            <Flex alignItems="center" justifyContent="center" height="200px">
              <Button colorScheme="blue" size="lg" onClick={handlePayment}>
                Schedule a Coaching Session
              </Button>
            </Flex>
          )}
        </>
      ) : (
        <Flex alignItems="center" justifyContent="center" height="200px">
          <Spinner size="xl" color="blue.500" />
          <Text ml={4} fontSize="xl" color="gray.500">
            Loading monthly coach feedback...
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default MonthlyCoachFeedback;
