import React from 'react';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const SignOut = ({ onSignOut }) => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    fetch('https://sbscoaches.com/auth/logout', {
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          onSignOut();
          navigate('/login');
        } else {
          console.error('Failed to sign out');
        }
      })
      .catch((error) => {
        console.error('Error during sign out:', error);
      });
  };

  return (
    <span onClick={handleSignOut}>Sign Out</span>
  );
};

export default SignOut;
