import { extendTheme } from '@chakra-ui/react';

const colors = {
  blue: {
    500: '#1DA1F2', // Base color
    200: '#90CDF4',
  },
  orange: {
    300: '#F6AD55',
  },
  cyan: {
    500: '#00BFFF',
  },
  gray: {
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    700: '#4A5568',
    800: '#1A202C',
  },
  purple: {
    300: '#D6BCFA',
  },
  red: {
    500: '#E53E3E',
  },
};

const theme = extendTheme({ colors });

export default theme;
