// src/Components/LandingPage.js
import React, { useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Header from './Header';
import Hero from './Hero';
import CallToAction from './CallToAction';
import Features from './Features';
import FoundersSection from './FoundersSection';
import Footer from './Footer';
import ScrollAnimationWrapper from './ScrollAnimationWrapper';

const LandingPage = () => {
  const [bgPosition, setBgPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const { clientX: x, clientY: y } = e;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const bgX = (x / windowWidth - 0.5) * 10; // Adjust multiplier for sensitivity
    const bgY = (y / windowHeight - 0.5) * 10;
    setBgPosition({ x: bgX, y: bgY });
  };

  return (
    <Flex
      direction="column"
      onMouseMove={handleMouseMove}
      position="relative"
    >
      <Box
        as={motion.div}
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg="gray.800"
        style={{
          transform: `translate(${bgPosition.x}px, ${bgPosition.y}px)`,
        }}
      />
      <Header />
      <Box zIndex="1" position="relative">
        <ScrollAnimationWrapper>
          <Hero />
        </ScrollAnimationWrapper>
        <ScrollAnimationWrapper>
          <Features />
        </ScrollAnimationWrapper>
        <ScrollAnimationWrapper>
          <FoundersSection />
        </ScrollAnimationWrapper>
        <ScrollAnimationWrapper>
          <CallToAction />
        </ScrollAnimationWrapper>
        <Footer />
      </Box>
    </Flex>
  );
};

export default LandingPage;
