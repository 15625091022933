// src/Components/Header.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Button,
  HStack,
  Text,
  Spacer,
  useBreakpointValue
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Box
      as="header"
      position="fixed"
      w="full"
      bg={scrolled ? 'white' : 'transparent'}
      boxShadow={scrolled ? 'sm' : 'none'}
      zIndex={50}
      transition="all 0.3s ease-in-out"
    >
      <Flex
        maxW="container.xl"
        mx="auto"
        py={4}
        px={6}
        alignItems="center"
      >
        <Flex alignItems="center" flexShrink={0}>
          <Text
            fontWeight="bold"
            fontSize={isMobile ? "sm" : "lg"}
            color={scrolled ? 'purple.600' : 'white'}
            mr={2}
          >
            Suite Business Solutions
          </Text>
          <Text
            fontWeight="semibold"
            fontSize={isMobile ? "xs" : "md"}
            color={scrolled ? 'purple.400' : 'whiteAlpha.800'}
          >
            SBS
          </Text>
        </Flex>
        <Spacer />
        <HStack spacing={4}>
          <Link to="/login">
            <Button
              variant="outline"
              colorScheme="purple"
              rounded="full"
              size={isMobile ? "xs" : "sm"}
            >
              Sign In
            </Button>
          </Link>
          <Link to="/signup">
            <Button
              colorScheme="purple"
              rounded="full"
              size={isMobile ? "xs" : "sm"}
              _hover={{
                bg: 'purple.600',
              }}
            >
              Start Free Trial
            </Button>
          </Link>
        </HStack>
      </Flex>
    </Box>
  );
};

export default Header;
