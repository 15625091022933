import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Heading,
  Text,
  Spinner,
  VStack,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  List,
  ListItem,
  ListIcon,
  Divider,
} from '@chakra-ui/react';
import { MdCheckCircle, MdInsights, MdTrendingUp } from 'react-icons/md';

const SquareSales = () => {
  const [salesData, setSalesData] = useState(null);
  const [aiAnalysis, setAiAnalysis] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const response = await axios.get('https://sbscoaches.com/api/square/total-sales', {
          withCredentials: true
        });
        setSalesData(response.data.salesData);
        setAiAnalysis(response.data.aiAnalysis);
      } catch (err) {
        console.error("Error fetching sales data:", err);
        setError('Failed to fetch sales data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchSalesData();
  }, []);

  if (loading) return <Spinner />;
  if (error) return <Box>Error: {error}</Box>;

  // Function to parse AI analysis text into structured data
  const parseAiAnalysis = (text) => {
    const sections = text.split('\n\n');
    return {
      overview: sections[0],
      insights: sections[1].split('\n').filter(item => item.trim() !== ''),
      recommendations: sections[2].split('\n').filter(item => item.trim() !== ''),
      smartGoals: sections[3].split('\n').filter(item => item.trim() !== '')
    };
  };

  const parsedAnalysis = aiAnalysis ? parseAiAnalysis(aiAnalysis) : null;

  return (
    <VStack spacing={8} align="stretch">
      <Heading size="lg">Square Sales Data and AI Insights</Heading>

      <StatGroup>
        <Stat>
          <StatLabel>Retail Sales</StatLabel>
          <StatNumber>${salesData.retail_sales.toFixed(2)}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Service Sales</StatLabel>
          <StatNumber>${salesData.service_sales.toFixed(2)}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Unique Clients</StatLabel>
          <StatNumber>{salesData.client_count}</StatNumber>
        </Stat>
      </StatGroup>

      {parsedAnalysis && (
        <VStack spacing={6} align="stretch">
          <Box>
            <Heading size="md">Overview</Heading>
            <Text>{parsedAnalysis.overview}</Text>
          </Box>

          <Divider />

          <Box>
            <Heading size="md">Key Insights</Heading>
            <List spacing={3}>
              {parsedAnalysis.insights.map((insight, index) => (
                <ListItem key={index}>
                  <ListIcon as={MdInsights} color="green.500" />
                  {insight}
                </ListItem>
              ))}
            </List>
          </Box>

          <Divider />

          <Box>
            <Heading size="md">Recommendations</Heading>
            <List spacing={3}>
              {parsedAnalysis.recommendations.map((rec, index) => (
                <ListItem key={index}>
                  <ListIcon as={MdCheckCircle} color="blue.500" />
                  {rec}
                </ListItem>
              ))}
            </List>
          </Box>

          <Divider />

          <Box>
            <Heading size="md">SMART Goals</Heading>
            <List spacing={3}>
              {parsedAnalysis.smartGoals.map((goal, index) => (
                <ListItem key={index}>
                  <ListIcon as={MdTrendingUp} color="purple.500" />
                  {goal}
                </ListItem>
              ))}
            </List>
          </Box>
        </VStack>
      )}
    </VStack>
  );
};

export default SquareSales;
