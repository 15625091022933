import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, VStack, Text, Button, Spinner, useToast,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
  List, ListItem, Flex, Badge, Heading, Textarea, HStack, Image, useDisclosure
} from '@chakra-ui/react';
import { FaSyncAlt, FaInstagram, FaChevronRight, FaEdit, FaCheck, FaImage } from 'react-icons/fa';

const Social = ({ userInfo }) => {
  const [trends, setTrends] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedTrend, setSelectedTrend] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [generatedPost, setGeneratedPost] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchTrends = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`https://sbscoaches.com/api/analyze-trends?category=${encodeURIComponent(userInfo.business_category)}`, {
        method: 'GET',
        credentials: 'include',
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setTrends(data.trends);
    } catch (error) {
      console.error('Error fetching trends:', error);
      setError('Unable to fetch trends. Try again?');
      toast({
        title: "Oops!",
        description: "We couldn't get the latest trends. Tap to retry.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [userInfo.business_category, toast]);

  useEffect(() => {
    if (userInfo && userInfo.business_category) {
      fetchTrends();
    }
  }, [userInfo, fetchTrends]);

  const openTrendModal = (category) => {
    setCurrentCategory(category);
    setIsModalOpen(true);
  };

  const selectTrend = async (trend) => {
    setSelectedTrend(trend);
    setIsModalOpen(false);
    await generatePost(trend);
  };

  const generatePost = async (trend) => {
    setLoading(true);
    try {
      const response = await fetch('https://sbscoaches.com/api/generate-social-post', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ businessCategory: userInfo.business_category, trend }),
        credentials: 'include',
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.error || `HTTP error! status: ${response.status}`);
      setGeneratedPost(data);
      console.log('Generated post data:', data);
    } catch (error) {
      console.error('Error generating social media post:', error);
      toast({
        title: "Post Generation Failed",
        description: "Unable to create post. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const generateImage = async () => {
    if (!generatedPost || !generatedPost.image_prompt) return;

    setImageLoading(true);
    setError(null);
    try {
      const response = await fetch('https://sbscoaches.com/api/generate-image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ image_prompt: generatedPost.image_prompt }),
        credentials: 'include',
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || `HTTP error! status: ${response.status}`);
      }

      if (!data.image) {
        throw new Error('No image data received from server');
      }

      setGeneratedPost(prev => ({ ...prev, image: data.image }));
    } catch (error) {
      console.error('Error generating image:', error);
      setError(error.message || 'Failed to generate image. Please try again.');
      toast({
        title: "Image Generation Failed",
        description: `Unable to create image: ${error.message}. Please try again.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setImageLoading(false);
    }
  };

  const handlePostToInstagram = () => {
    onOpen(); // Open the modal with instructions
  };

  const renderTrendList = (items) => (
    <List spacing={3}>
      {items.map((item, index) => (
        <ListItem key={index} onClick={() => selectTrend(item.query || item)}>
          <Flex justify="space-between" align="center" p={3} bg="gray.50" borderRadius="md" cursor="pointer">
            <Text fontSize="sm" fontWeight="medium">{item.query || item}</Text>
            {item.value && (
              <Badge colorScheme={item.value.includes('+') ? "green" : "blue"}>{item.value}</Badge>
            )}
          </Flex>
        </ListItem>
      ))}
    </List>
  );

  return (
    <Box p={4}>
      <VStack align="stretch" spacing={6}>
        <Heading size="lg" textAlign="center">See What's Trending</Heading>

        <Text textAlign="center" fontSize="md" color="gray.600" mb={4}>
          Trends are your way out of the void. Tap in, hack the algorithm, or disappear into digital dust. 
          The choice is yours. #GlitchOrThrive
        </Text>

        {loading && <Spinner size="xl" alignSelf="center" />}
        {error && (
          <Button colorScheme="red" onClick={fetchTrends} size="lg" w="full">
            Retry
          </Button>
        )}

        {trends && !generatedPost && (
          <>
            <Button
              rightIcon={<FaChevronRight />}
              onClick={() => openTrendModal('topics')}
              size="lg"
              w="full"
            >
              Topics
            </Button>
            <Button
              rightIcon={<FaChevronRight />}
              onClick={() => openTrendModal('rising')}
              size="lg"
              w="full"
            >
              Rising
            </Button>
            <Button
              rightIcon={<FaChevronRight />}
              onClick={() => openTrendModal('popular')}
              size="lg"
              w="full"
            >
              Popular
            </Button>
          </>
        )}

        {generatedPost && (
          <Box mt={4} p={5} borderWidth="1px" borderRadius="lg" boxShadow="md">
            <Heading size="md" mb={4}>Your Post</Heading>
            {isEditing ? (
              <Textarea
                value={generatedPost.post_content}
                onChange={(e) => setGeneratedPost({...generatedPost, post_content: e.target.value})}
                minHeight="150px"
                mb={4}
              />
            ) : (
              <Text mb={4}>{generatedPost.post_content}</Text>
            )}
            {generatedPost.image_prompt && (
              <Box mb={4}>
                <Heading size="sm" mb={2}>Image Recommendation:</Heading>
                <Text mb={2}>{generatedPost.image_prompt}</Text>
                <Button
                  leftIcon={<FaImage />}
                  onClick={generateImage}
                  isLoading={imageLoading}
                  loadingText="Generating..."
                  colorScheme="teal"
                  size="sm"
                >
                  Generate Image
                </Button>
              </Box>
            )}
            {generatedPost.image && (
              <Box mb={4}>
                <Image
                  src={`data:image/png;base64,${generatedPost.image}`}
                  alt="Generated image"
                  borderRadius="md"
                  maxWidth="100%"
                  height="auto"
                />
              </Box>
            )}
            <HStack spacing={4} mb={4}>
              <Button
                leftIcon={isEditing ? <FaCheck /> : <FaEdit />}
                onClick={() => setIsEditing(!isEditing)}
                size="sm"
              >
                {isEditing ? "Save" : "Edit"}
              </Button>
              <Button
                leftIcon={<FaInstagram />}
                colorScheme="pink"
                onClick={handlePostToInstagram}
                size="sm"
              >
                Post
              </Button>
            </HStack>
            <Text fontSize="sm" color="gray.500">Trend: {selectedTrend}</Text>
          </Box>
        )}

        <Button
          leftIcon={<FaSyncAlt />}
          colorScheme="teal"
          onClick={() => { fetchTrends(); setGeneratedPost(null); }}
          size="lg"
          w="full"
        >
          Refresh
        </Button>

        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="full">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {currentCategory === 'topics' ? 'Topics' :
               currentCategory === 'rising' ? 'Rising' :
               'Popular'}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {currentCategory === 'topics' && renderTrendList(trends?.related_topics || [])}
              {currentCategory === 'rising' && renderTrendList(trends?.related_queries?.rising || [])}
              {currentCategory === 'popular' && renderTrendList(trends?.related_queries?.top || [])}
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Post to Instagram</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack align="stretch" spacing={4}>
                <Text>Follow these steps to post on Instagram:</Text>
                <Text>1. Copy the generated post content.</Text>
                <Text>2. Save the generated image to your device.</Text>
                <Text>3. Open the Instagram app on your phone.</Text>
                <Text>4. Tap the '+' icon to create a new post.</Text>
                <Text>5. Select the saved image from your device.</Text>
                <Text>6. Paste the copied content into the caption field.</Text>
                <Text>7. Add any additional hashtags or mentions.</Text>
                <Text>8. Tap 'Share' to post on Instagram.</Text>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </VStack>
    </Box>
  );
};

export default Social;
