import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Heading, Text, useToast, VStack, Icon, } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { FaCreditCard } from 'react-icons/fa';

const SubscriptionManagement = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleManageSubscription = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://sbscoaches.com/api/customer-portal', {}, { withCredentials: true });
      window.location.href = response.data.url;
    } catch (error) {
      setLoading(false);
      toast({
        title: 'Error',
        description: 'Failed to access the customer portal.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleBack = () => {
    navigate('/user-profile');
  };

  return (
    <Box maxWidth="800px" margin="0 auto" p={8}>
      <Button
        leftIcon={<ArrowBackIcon />}
        variant="ghost"
        size="sm"
        mb={4}
        onClick={handleBack}
      >
        Return to Profile
      </Button>
      <Box textAlign="center" mb={8}>
        <Icon as={FaCreditCard} w={10} h={10} mb={4} color="blue.500" />
        <Heading as="h3" size="lg" mb={4}>
          Manage Your Subscription
        </Heading>
        <Text fontSize="lg">
          Manage and customize your subscription options.
        </Text>
      </Box>
      <VStack spacing={4} align="stretch">
        <Button
          colorScheme="teal"
          size="lg"
          onClick={handleManageSubscription}
          isLoading={loading}
        >
          Manage Subscription
        </Button>
      </VStack>
    </Box>
  );
};

export default SubscriptionManagement;
