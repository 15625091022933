import React, { useState } from 'react';
import { Box, Button, Text, VStack, Tag, HStack, Spinner, Heading } from '@chakra-ui/react';

const TrendAnalyzer = ({ businessCategory, onComplete }) => {
  const [trends, setTrends] = useState({ topics: [], queries: { rising: [], top: [] } });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTrends = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`https://sbscoaches.com/api/analyze-trends?category=${encodeURIComponent(businessCategory)}`, {
        method: 'GET',
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setTrends({
        topics: data.trends.related_topics || [],
        queries: data.trends.related_queries || { rising: [], top: [] }
      });
      if (onComplete) {
        onComplete(data.trends);
      }
    } catch (error) {
      console.error('Error analyzing trends:', error);
      setError('Failed to analyze trends. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const getSearchTerm = (category) => {
    const categoryMap = {
      "Hair Stylists": "hair styles",
      "Barbers": "barbering",
      "Nail Technicians": "nail art",
      "Spas and Wellness Professionals": "spa treatments",
      "Skin Care Specialists": "skincare",
      "Makeup and Cosmetic Artists": "makeup trends",
      "Beauty Product Suppliers": "beauty products",
      "Aesthetic and Cosmetic Specialists": "cosmetic procedures",
      "Beauty and Cosmetology Educators": "beauty education",
      "Tanning Specialists": "tanning",
      "Fragrance Experts": "fragrance",
      "Beauty Subscription Curators and Online Retailers": "beauty subscriptions",
      "Mobile Beauty Service Providers and Freelancers": "mobile beauty services"
    };
    return categoryMap[category] || category.toLowerCase();
  };

  return (
    <Box p={5} borderWidth="1px" borderRadius="lg" boxShadow="md">
      <Button
        onClick={fetchTrends}
        isLoading={loading}
        mb={4}
        colorScheme="teal"
        variant="solid"
      >
        Analyze {getSearchTerm(businessCategory)} Trends
      </Button>
      {error && <Text color="red.500">{error}</Text>}
      {loading && <Spinner color="teal.500" />}
      <VStack align="start" spacing={4}>
        {trends.topics.length > 0 && (
          <>
            <Heading size="md" color="teal.600">Top Rising Topics for {getSearchTerm(businessCategory)}:</Heading>
            {trends.topics.map((topic, index) => (
              <HStack key={index} spacing={4} p={2} bg="teal.50" borderRadius="md">
                <Tag size="md" variant="subtle" colorScheme="teal">
                  {index + 1}
                </Tag>
                <Text fontSize="md" color="teal.800">{topic}</Text>
              </HStack>
            ))}
          </>
        )}
        {trends.queries.rising.length > 0 && (
          <>
            <Heading size="md" color="teal.600">Rising Related Queries:</Heading>
            {trends.queries.rising.map((query, index) => (
              <HStack key={index} spacing={4} p={2} bg="teal.50" borderRadius="md">
                <Tag size="md" variant="subtle" colorScheme="teal">
                  {index + 1}
                </Tag>
                <Text fontSize="md" color="teal.800">{query.query}</Text>
                <Tag size="sm" colorScheme="green">{query.value}</Tag>
              </HStack>
            ))}
          </>
        )}
        {trends.queries.top.length > 0 && (
          <>
            <Heading size="md" color="teal.600">Top Related Queries:</Heading>
            {trends.queries.top.map((query, index) => (
              <HStack key={index} spacing={4} p={2} bg="teal.50" borderRadius="md">
                <Tag size="md" variant="subtle" colorScheme="teal">
                  {index + 1}
                </Tag>
                <Text fontSize="md" color="teal.800">{query.query}</Text>
                <Tag size="sm" colorScheme="blue">{query.value}</Tag>
              </HStack>
            ))}
          </>
        )}
      </VStack>
    </Box>
  );
};

export default TrendAnalyzer;
