// StripeSuccessComponent.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const StripeSuccessComponent = () => {
  const { checkAuth, isLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleStripeSuccess = async () => {
      await checkAuth();
      navigate('/welcome');
    };

    if (!isLoading) {
      handleStripeSuccess();
    }
  }, [checkAuth, navigate, isLoading]);

  return <div>Processing your subscription...</div>;
};

export default StripeSuccessComponent;
