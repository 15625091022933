// src/Components/Unsubscribe.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  Heading,
  VStack,
  Alert,
  AlertIcon,
  useColorModeValue,
} from '@chakra-ui/react';

const Unsubscribe = () => {
  const [message, setMessage] = useState('');  // State to hold the response message
  const location = useLocation();

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const sectionBgColor = useColorModeValue('white', 'gray.700');
  const headingColor = useColorModeValue('blue.600', 'blue.300');

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const encodedEmail = pathParts[pathParts.length - 1];

    if (encodedEmail) {
      handleUnsubscribe(encodedEmail);  // Automatically trigger the unsubscribe process
    }
  }, [location]);

  const handleUnsubscribe = async (encodedEmail) => {
    try {
      const response = await fetch(`/api/unsubscribe/${encodedEmail}`, {
        method: 'GET',  // Send a GET request to the unsubscribe API
      });
      const data = await response.json();  // Parse the JSON response
      if (response.ok) {
        setMessage(data.message);  // Set the message based on the response
      } else {
        setMessage(data.error || 'An error occurred. Please try again.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');  // Handle network or other errors
    }
  };

  return (
    <Box bg={bgColor} minHeight="100vh" py={12}>
      <Container maxW="xl">
        <VStack spacing={8} align="stretch">
          <Heading as="h1" size="2xl" textAlign="center" color={headingColor}>
            Unsubscribe
          </Heading>
          <Box bg={sectionBgColor} p={6} borderRadius="md" boxShadow="md">
            {message ? (
              <Alert status={message.includes('successfully') ? 'success' : 'error'}>
                <AlertIcon />
                {message}  {/* Display the message */}
              </Alert>
            ) : (
              <Alert status="info">
                <AlertIcon />
                Processing your request...
              </Alert>
            )}
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default Unsubscribe;
