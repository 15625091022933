import React, { useState } from 'react';
import {
  Box, Button, Input, FormControl, FormLabel, Heading, Text, VStack, useColorModeValue, useToast
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage("Passwords don't match. Please try again.");
      return;
    }
    try {
      const response = await fetch('https://sbscoaches.com/auth/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, new_password: newPassword }),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
        toast({
          title: 'Password reset successfully',
          description: data.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => navigate('/login'), 3000);
      } else {
        setMessage(data.error || 'Failed to reset password. Please try again.');
        toast({
          title: 'Error',
          description: data.error || 'Failed to reset password. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      setMessage('Failed to reset password. Please try again.');
      toast({
        title: 'Error',
        description: 'Failed to reset password. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const bg = useColorModeValue('purple.50', 'purple.900');
  const color = useColorModeValue('gray.800', 'white');
  const tabBg = useColorModeValue('white', 'gray.800');

  return (
    <Box bg={bg} p={8} borderRadius="lg" boxShadow="lg" w="full" maxW="md" mx="auto" textAlign="center">
      <Heading mb={4} color={color}>Reset Password</Heading>
      <Text mb={6} color={color}>
        Enter your new password below to reset your account.
      </Text>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl id="new-password">
            <FormLabel color={color}>New Password</FormLabel>
            <Input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              bg={tabBg}
              color={color}
            />
          </FormControl>
          <FormControl id="confirm-password">
            <FormLabel color={color}>Confirm New Password</FormLabel>
            <Input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              bg={tabBg}
              color={color}
            />
          </FormControl>
          <Button type="submit" colorScheme="purple" width="full" mt={4}>
            Reset Password
          </Button>
        </VStack>
      </form>
      {message && <Text mt={4} color={color}>{message}</Text>}
    </Box>
  );
};

export default ResetPassword;
