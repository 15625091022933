import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Text, Spinner, Divider, VStack } from '@chakra-ui/react';

const PastAppointments = () => {
    const [totalServicePayments, setTotalServicePayments] = useState(0);
    const [totalItemPayments, setTotalItemPayments] = useState(0); // For product/retail payments
    const [totalOrders, setTotalOrders] = useState([]); // For order summary
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPastAppointmentsAndOrders = async () => {
            try {
                // Fetch past appointments summary
                const appointmentsResponse = await axios.get('/api/get-past-appointments');
                const { total_service_payments, total_item_payments } = appointmentsResponse.data;

                // Fetch orders summary
                const ordersResponse = await axios.get('/api/get-orders-summary');
                const ordersData = ordersResponse.data;

                // Set state variables for appointments
                setTotalServicePayments(total_service_payments);
                setTotalItemPayments(total_item_payments);

                // Set state variables for orders
                setTotalOrders(ordersData);

                setLoading(false);
            } catch (err) {
                setError('Failed to fetch past appointments and orders summary.');
                setLoading(false);
            }
        };

        fetchPastAppointmentsAndOrders();
    }, []);

    if (loading) return <Spinner />;

    if (error) return <Text color="red.500">{error}</Text>;

    return (
        <Box p={4}>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>Past Appointments and Sales Summary</Text>

            <VStack spacing={4} align="start">
                <Box>
                    <Text fontWeight="bold">Total Service Payments (Last Month):</Text>
                    <Text>${totalServicePayments.toFixed(2)}</Text>
                </Box>
                <Box>
                    <Text fontWeight="bold">Total Product/Retail Payments (Last Month):</Text>
                    <Text>${totalItemPayments.toFixed(2)}</Text>
                </Box>
            </VStack>

            <Divider my={6} />

            <Text fontSize="2xl" fontWeight="bold" mb={4}>Orders Summary (Past Two Weeks)</Text>
            <VStack spacing={4} align="start">
                {totalOrders.map((order, index) => (
                    <Box key={index}>
                        <Text fontWeight="bold">Order ID: {order.order_id}</Text>
                        <Text>Total Money: ${order.total_money.toFixed(2)}</Text>
                        
                        {order.items && order.items.map((item, idx) => (
                            <Box key={idx} pl={4} mt={2}>
                                <Text>Item Name: {item.item_name}</Text>
                                <Text>Quantity: {item.quantity}</Text>
                                <Text>Base Price: ${item.base_price.toFixed(2)}</Text>
                                {/* Display the category name and ID here */}
                                <Text>Category: {item.category_name} (ID: {item.category_id})</Text>
                                <Divider />
                            </Box>
                        ))}
                        <Divider />
                    </Box>
                ))}
            </VStack>
        </Box>
    );
};

export default PastAppointments;
