import React from 'react';
import { Box, Heading, Text, Button, useColorModeValue } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

const ThankYou = () => {
  const navigate = useNavigate();
  const bg = useColorModeValue('teal.300', 'teal.500');
  const color = useColorModeValue('white', 'gray.800');
  const buttonColor = useColorModeValue('teal.600', 'teal.200');

  const handleBack = () => {
    navigate('/user-profile');
  };

  return (
    <Box position="relative" bg={bg} p={8} borderRadius="lg" boxShadow="lg" w="full" maxW="md" mx="auto" textAlign="center">
      <Button
        leftIcon={<ArrowBackIcon />}
        variant="solid"
        size="sm"
        onClick={handleBack}
        position="absolute"
        top={4}
        left={4}
        bg={buttonColor}
        color={color}
        _hover={{ bg: useColorModeValue('teal.700', 'teal.300') }}
      >
        Return to Profile
      </Button>

      <Heading mt={12} mb={4} color={color}>Thank You!</Heading>
      <Text color={color}>
        Your message has been sent successfully. We will get back to you as soon as possible.
      </Text>
    </Box>
  );
};

export default ThankYou;
