import React, { useState } from 'react';
import {
  Box, Button, Input, FormControl, FormLabel, Heading, Text, VStack, useColorModeValue
} from '@chakra-ui/react';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://sbscoaches.com/auth/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      setMessage(data.message);
    } catch (error) {
      setMessage('Failed to send reset email. Please try again.');
    }
  };

  const bg = useColorModeValue('orange.300', 'orange.500');
  const color = useColorModeValue('white', 'gray.800');

  return (
    <Box bg={bg} p={8} borderRadius="lg" boxShadow="lg" w="full" maxW="md" mx="auto" textAlign="center">
      <Heading mb={4} color={color}>Forgot Password</Heading>
      <Text mb={6} color={color}>
        Enter your email address and we'll send you a link to reset your password.
      </Text>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl id="email">
            <FormLabel color={color}>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              bg="white"
              color="black"
            />
          </FormControl>
          <Button type="submit" colorScheme="cyan" width="full">Send Reset Link</Button>
        </VStack>
      </form>
      {message && <Text mt={4} color={color}>{message}</Text>}
    </Box>
  );
};

export default ForgotPassword;
