import React, { useEffect, useState } from 'react';
import { Box, Heading, VStack, Link, Button, Icon, useColorModeValue } from '@chakra-ui/react';
import { ArrowBackIcon, DownloadIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { FaFileAlt } from 'react-icons/fa';

const UserMarketingMaterials = () => {
  const [materials, setMaterials] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const bg = useColorModeValue('gray.100', 'gray.800');
  const color = useColorModeValue('blue.600', 'blue.200');

  useEffect(() => {
    const fetchMaterials = async () => {
      try {
        const response = await fetch('https://sbscoaches.com/api/user-marketing-materials', {
          credentials: 'include'
        });
        if (response.ok) {
          const data = await response.json();
          setMaterials(data);
        } else {
          const errorData = await response.json();
          setError(errorData.error || 'Failed to fetch marketing materials');
        }
      } catch (error) {
        setError('An error occurred while fetching marketing materials');
      }
    };
    fetchMaterials();
  }, []);

  const handleBack = () => {
    navigate('/user-profile');
  };

  if (error) {
    return <Box>{error}</Box>;
  }

  const getFileUrl = (filename) => {
    return `https://sbscoaches.com/api/user-files/${filename}`;
  };

  return (
    <Box maxWidth="800px" margin="0 auto" p={8} bg={bg} borderRadius="md" boxShadow="lg">
      <Button
        leftIcon={<ArrowBackIcon />}
        variant="ghost"
        size="sm"
        mb={4}
        onClick={handleBack}
        colorScheme="blue"
      >
        Return to Profile
      </Button>
      <Heading as="h1" size="xl" mb={8} textAlign="center" color={color}>
        Your Marketing Materials
      </Heading>
      <VStack spacing={6} align="stretch">
        {materials && (
          <>
            <Box p={4} bg="white" borderRadius="md" shadow="md">
              <Heading as="h3" size="md" mb={2} display="flex" alignItems="center">
                <Icon as={FaFileAlt} mr={2} />
                Slogan
              </Heading>
              <Link href={getFileUrl(materials.slogan)} isExternal>
                <Button rightIcon={<DownloadIcon />} colorScheme="teal" variant="outline">
                  Download Slogan
                </Button>
              </Link>
            </Box>
            <Box p={4} bg="white" borderRadius="md" shadow="md">
              <Heading as="h3" size="md" mb={2} display="flex" alignItems="center">
                <Icon as={FaFileAlt} mr={2} />
                Description
              </Heading>
              <Link href={getFileUrl(materials.description)} isExternal>
                <Button rightIcon={<DownloadIcon />} colorScheme="teal" variant="outline">
                  Download Description
                </Button>
              </Link>
            </Box>
            {materials.logo && (
              <Box p={4} bg="white" borderRadius="md" shadow="md">
                <Heading as="h3" size="md" mb={2} display="flex" alignItems="center">
                  <Icon as={FaFileAlt} mr={2} />
                  Logo
                </Heading>
                <Link href={getFileUrl(materials.logo)} isExternal>
                  <Button rightIcon={<DownloadIcon />} colorScheme="teal" variant="outline">
                    Download Logo
                  </Button>
                </Link>
              </Box>
            )}
          </>
        )}
      </VStack>
    </Box>
  );
};

export default UserMarketingMaterials;
