import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Select,
  Button,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext'; // Import authentication context

const Preferences = ({ userInfo, onSave }) => {
  const [preferences, setPreferences] = useState({
    aiPersonality: 'friendly',
    language: 'english',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); // For error handling
  const toast = useToast();
  const { isAuthenticated } = useAuth(); // Get authentication status

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const response = await axios.get('https://sbscoaches.com/api/user_preferences', { withCredentials: true });
        setPreferences(response.data.preferences); // Use response data for preferences
      } catch (error) {
        console.error('Error fetching preferences:', error);
        toast({
          title: 'Error fetching preferences',
          description: 'Please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };
    if (isAuthenticated) {
      fetchPreferences();
    }
  }, [isAuthenticated, toast]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPreferences(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isAuthenticated) {
      toast({
        title: "Unauthorized",
        description: "You must be logged in to update preferences.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    setError(null); // Reset error state

    try {
      const response = await axios.put('https://sbscoaches.com/api/user_preferences', preferences, { withCredentials: true });
      if (response.status === 200) {
        onSave(preferences); // Pass the updated preferences back to the parent
        toast({
          title: "Preferences updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error('Failed to update preferences');
      }
    } catch (error) {
      console.error('Error updating preferences:', error);
      setError(error.message || 'An unexpected error occurred');
      toast({
        title: "Error updating preferences",
        description: error.message || 'An unexpected error occurred',
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit} p={4} shadow="md" borderWidth="1px" borderRadius="md">
      <VStack spacing={6} align="stretch">
        <Heading size="lg" textAlign="center">Preferences</Heading>

        {error && <p style={{ color: 'red' }}>{error}</p>}

        <FormControl>
          <FormLabel>AI Assistant Style</FormLabel>
          <Select
            name="aiPersonality"
            value={preferences.aiPersonality}
            onChange={handleChange}
            size="lg"
          >
            <option value="friendly">Friendly and Casual</option>
            <option value="professional">Professional and Formal</option>
            <option value="chaotic_creative">Chaotic Creative</option>
            <option value="glamorous_diva">Glamorous Diva</option>
            <option value="zen_master">Zen Master</option>
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel>Language</FormLabel>
          <Select
            name="language"
            value={preferences.language}
            onChange={handleChange}
            size="lg"
          >
            <option value="english">English</option>
            <option value="spanish" disabled>Spanish (Coming Soon)</option>
          </Select>
        </FormControl>

        <Button
          type="submit"
          colorScheme="purple"
          isLoading={loading}
          size="lg"
          fontSize="md"
        >
          Save Preferences
        </Button>
      </VStack>
    </Box>
  );
};

export default Preferences;
