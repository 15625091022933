import React from 'react';
import { Box, Image, Text, Heading, VStack, Container, SimpleGrid } from '@chakra-ui/react';

const FounderCard = ({ name, role, imageUrl, bio, quote }) => (
  <VStack
    spacing={4}
    align="center"
    p={6}
    bg="white"
    boxShadow="md"
    borderRadius="lg"
    maxW="400px"
    m="auto"
  >
    <Image
      src={imageUrl}
      alt={name}
      borderRadius="full"
      boxSize="150px"
      objectFit="cover"
      mb={4}
    />
    <Heading as="h3" size="lg" mb={2}>
      {name}
    </Heading>
    <Text fontWeight="bold" color="gray.600">
      {role}
    </Text>
    <Text textAlign="center" color="gray.700">
      {bio}
    </Text>
    <Text fontStyle="italic" color="purple.500" mt={4}>
      "{quote}"
    </Text>
  </VStack>
);

const FoundersSection = () => (
  <Box as="section" py={20} bg="gray.50" id="founders">
    <Container maxW="container.xl">
      <Heading as="h2" size="xl" textAlign="center" mb={12} color="gray.800">
        The Minds Behind Suite Business Solutions
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <FounderCard
          name="Kimi Bennett"
          role="Co-Founder"
          imageUrl="kim-profile.jpeg"
          bio="Kim Bennett's remarkable career spans over three decades in the beauty industry. A Senior National Educator for major hair care brands, she's pioneered the salon suite business model and earned multiple awards for her expertise. As an acclaimed cosmetologist and mentor, Kim has been featured in top industry publications and podcasts. Now, as Manager of Business Development for Independent Stylists, she continues to inspire and empower beauty professionals worldwide, shaping the future of the industry through her leadership and innovation."
          quote="Our mission is to empower beauty professionals with the tools they need to thrive in today's digital landscape."
        />
        <FounderCard
          name="Mikel Sandoval"
          role="Co-Founder"
          imageUrl="mikel-profile.jpg"
          bio="With over 40 years in the hair design industry, Michael Sandoval is a celebrated designer and business coach. His expertise spans salon management, retail sales, and innovative industry approaches. As a former international trainer, Michael has inspired stylists worldwide and now focuses on helping independent professionals boost their income and work smarter."
          quote="We're combining cutting-edge technology with deep industry expertise to revolutionize the beauty business."
        />
      </SimpleGrid>
    </Container>
  </Box>
);

export default FoundersSection;
