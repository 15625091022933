import React, { useState } from 'react';
import {
  Box, Button, Input, FormControl, FormLabel, Heading, Text, VStack, useColorModeValue, Textarea, Flex
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const navigate = useNavigate();

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!recaptchaToken) {
      setResponseMessage('Please complete the reCAPTCHA');
      return;
    }
    try {
      const response = await fetch('https://sbscoaches.com/auth/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          message,
          recaptchaToken,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setResponseMessage('Your message has been sent successfully!');
        setTimeout(() => {
          navigate('/thank-you'); // Redirect to thank you page if desired
        }, 2000);
      } else {
        setResponseMessage(data.error || 'Failed to send message. Please try again.');
      }
    } catch (error) {
      setResponseMessage('Failed to send message. Please try again.');
    }
  };

  const handleBack = () => {
    navigate('/user-profile');
  };

  const bg = useColorModeValue('teal.300', 'teal.500');
  const color = useColorModeValue('white', 'gray.800');

  return (
    <Box bg={bg} p={8} borderRadius="lg" boxShadow="lg" w="full" maxW="md" mx="auto">
      <Flex justifyContent="flex-start" mb={4}>
        <Button
          leftIcon={<ArrowBackIcon />}
          variant="ghost"
          size="sm"
          onClick={handleBack}
          color={color}
        >
          Return to Profile
        </Button>
      </Flex>

      <Heading mb={4} color={color} textAlign="center">Contact Us</Heading>
      <Text mb={6} color={color} textAlign="center">
        We'd love to hear from you. Please fill out the form below to send us a message.
      </Text>

      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl id="name">
            <FormLabel color={color}>Name</FormLabel>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              bg="white"
              color="black"
            />
          </FormControl>
          <FormControl id="email">
            <FormLabel color={color}>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              bg="white"
              color="black"
            />
          </FormControl>
          <FormControl id="message">
            <FormLabel color={color}>Message</FormLabel>
            <Textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              bg="white"
              color="black"
            />
          </FormControl>
          <ReCAPTCHA
            sitekey="6LezgAEqAAAAAFG-hFQN6HEnLJvNkNYuX0nwkZdK"
            onChange={handleRecaptchaChange}
          />
          <Button type="submit" colorScheme="cyan" width="full">Send Message</Button>
        </VStack>
      </form>
      {responseMessage && <Text mt={4} color={color}>{responseMessage}</Text>}
    </Box>
  );
};

export default ContactUs;
