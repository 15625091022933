// CreateMarketingComponent.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  Select,
  Button,
  VStack,
  useToast,
  useColorModeValue,
  Grid,
  GridItem,
  Spinner,
  useDisclosure,
  Modal,
  Icon,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CircularProgress,
  CircularProgressLabel,
  Flex
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { FaBullhorn } from 'react-icons/fa';
import ScheduleCoaching from './ScheduleCoaching';

const CreateMarketingComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const folder_path = location.state?.folder_path;

  console.log('Received folder_path:', folder_path); // Add this line for debugging

  const [selectedWords, setSelectedWords] = useState({
    businessCategory: '',
    vibe: '',
    innovation: '',
    expression: '',
    connection: '',
    vision: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [seconds, setSeconds] = useState(15);
  const [isPaid, setIsPaid] = useState(false);
  const locationSearch = useLocation().search;

  useEffect(() => {
    const query = new URLSearchParams(locationSearch);
    if (query.get('payment') === 'success') {
      setIsPaid(true);
    }
  }, [locationSearch]);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prev) => {
        if (prev === 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    if (seconds === 0) {
      onClose();
    }

    return () => clearInterval(timer);
  }, [seconds, onClose]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.values(selectedWords).includes('')) {
      toast({
        title: 'Incomplete Selection',
        description: 'Please select one word from each category.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);

    const requestBody = {
      folder_path: folder_path,
      selectedWords: selectedWords,
    };

    console.log('Request Body:', requestBody);

    try {
      const response = await axios.post('https://sbscoaches.com/api/create-marketing-materials', requestBody, {
        withCredentials: true,
      });
      console.log('Response from create-marketing-materials:', response.data);

      const { sloganFilePath, descriptionFilePath, logoFilePath } = response.data;

      // Save to database
      const saveResponse = await axios.post('https://sbscoaches.com/api/save-marketing-materials', {
        slogan: sloganFilePath.split('/').pop(),
        description: descriptionFilePath.split('/').pop(),
        logo: logoFilePath ? logoFilePath.split('/').pop() : null
      }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log('Response from save-marketing-materials:', saveResponse.data);

      onOpen();
      setIsLoading(false);
      setSeconds(15); // Reset the timer for modal countdown
    } catch (error) {
      console.error('Error creating the marketing materials:', error.response || error.message || error);
      setIsLoading(false);
      toast({
        title: 'Error',
        description: 'An error occurred while submitting. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleNavigateToMarketingMaterials = () => {
    navigate('/user-marketing-materials');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedWords((prevWords) => ({
      ...prevWords,
      [name]: value,
    }));
  };

  const handleBack = () => {
    navigate('/user-profile');
  };

  const handlePayment = () => {
    window.location.href = 'https://book.stripe.com/bIY00Ybsk7Ew14Q6ox';
  };

  return (
    <Box maxWidth="800px" margin="0 auto" p={8}>
      <Button
        leftIcon={<ArrowBackIcon />}
        variant="ghost"
        size="sm"
        mb={4}
        onClick={handleBack}
      >
        Back to Profile
      </Button>
      <Box textAlign="center">
        <Icon as={FaBullhorn} w={10} h={10} mb={4} color="blue.500" />
        <Heading as="h3" size="lg" mb={4}>
          Marketing Magic 🎩✨
        </Heading>
      </Box>
      <Heading as="h1" size="2xl" mb={8} textAlign="center">
        Unleash Your Brand's Superpowers! 🦸‍♂️💥
      </Heading>
      <Text fontSize="xl" mb={6} textAlign="center">
        Create Your Unique Marketing Identity: Logo, Tagline, and Description 🖌️🚀
      </Text>
      <form onSubmit={handleSubmit}>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6}>
          <GridItem>
            <VStack spacing={6} align="stretch">
              <Box>
                <Text fontSize="xl" mb={2}>
                  Business Category:
                </Text>
                <Select name="businessCategory" value={selectedWords.businessCategory} onChange={handleChange} bg={bgColor}>
                  <option value="">Select...</option>
                  <option value="Hair Stylists and Barbers">Hair Stylists and Barbers</option>
                  <option value="Nail Technicians">Nail Technicians</option>
                  <option value="Spas and Wellness Professionals">Spas and Wellness Professionals</option>
                  <option value="Skin Care Specialists">Skin Care Specialists</option>
                  <option value="Makeup and Cosmetic Artists">Makeup and Cosmetic Artists</option>
                  <option value="Beauty Product Suppliers">Beauty Product Suppliers</option>
                  <option value="Aesthetic and Cosmetic Specialists">Aesthetic and Cosmetic Specialists</option>
                  <option value="Beauty and Cosmetology Educators">Beauty and Cosmetology Educators</option>
                  <option value="Tanning Specialists">Tanning Specialists</option>
                  <option value="Fragrance Experts">Fragrance Experts</option>
                  <option value="Beauty Subscription Curators and Online Retailers">Beauty Subscription Curators and Online Retailers</option>
                  <option value="Mobile Beauty Service Providers and Freelancers">Mobile Beauty Service Providers and Freelancers</option>
                </Select>
              </Box>
              <Box>
                <Text fontSize="xl" mb={2}>
                  Vibe:
                </Text>
                <Select name="vibe" value={selectedWords.vibe} onChange={handleChange} bg={bgColor}>
                  <option value="">Select...</option>
                  <option value="Funky">Funky</option>
                  <option value="Vibrant">Vibrant</option>
                  <option value="Mysterious">Mysterious</option>
                  <option value="Conservative">Conservative</option>
                  <option value="Professional">Professional</option>
                </Select>
              </Box>
              <Box>
                <Text fontSize="xl" mb={2}>
                  Innovation:
                </Text>
                <Select name="innovation" value={selectedWords.innovation} onChange={handleChange} bg={bgColor}>
                  <option value="">Select...</option>
                  <option value="Cutting-edge">Cutting-edge</option>
                  <option value="Retro">Retro</option>
                  <option value="Experimental">Experimental</option>
                  <option value="Traditional">Traditional</option>
                  <option value="Modern">Modern</option>
                </Select>
              </Box>
              <Box>
                <Text fontSize="xl" mb={2}>
                  Expression:
                </Text>
                <Select name="expression" value={selectedWords.expression} onChange={handleChange} bg={bgColor}>
                  <option value="">Select...</option>
                  <option value="Bold">Bold</option>
                  <option value="Whimsical">Whimsical</option>
                  <option value="Reflective">Reflective</option>
                  <option value="Elegant">Elegant</option>
                  <option value="Sophisticated">Sophisticated</option>
                </Select>
              </Box>
            </VStack>
          </GridItem>
          <GridItem>
            <VStack spacing={6} align="stretch">
              <Box>
                <Text fontSize="xl" mb={2}>
                  Connection:
                </Text>
                <Select name="connection" value={selectedWords.connection} onChange={handleChange} bg={bgColor}>
                  <option value="">Select...</option>
                  <option value="Empathetic">Empathetic</option>
                  <option value="Inspiring">Inspiring</option>
                  <option value="Joyful">Joyful</option>
                  <option value="Trustworthy">Trustworthy</option>
                  <option value="Reliable">Reliable</option>
                </Select>
              </Box>
              <Box>
                <Text fontSize="xl" mb={2}>
                  Vision:
                </Text>
                <Select name="vision" value={selectedWords.vision} onChange={handleChange} bg={bgColor}>
                  <option value="">Select...</option>
                  <option value="Visionary">Visionary</option>
                  <option value="Grounded">Grounded</option>
                  <option value="Dreamy">Dreamy</option>
                  <option value="Practical">Practical</option>
                  <option value="Innovative">Innovative</option>
                </Select>
              </Box>
            </VStack>
          </GridItem>
        </Grid>
        <Button
          type="submit"
          colorScheme="blue"
          size="lg"
          mt={8}
          isLoading={isLoading}
          loadingText="Magic Is Happening..."
          spinner={<Spinner size="sm" color="white" />}
        >
          Submit
        </Button>
      </form>
      <Box mt={4}>
        {isPaid ? (
          <ScheduleCoaching calendlyUrl="https://calendly.com/saloncoach100/live-business-coaching-session" />
        ) : (
          <Flex alignItems="center" justifyContent="center" height="200px">
            <Button colorScheme="blue" size="lg" onClick={handlePayment}>
              Schedule a Coaching Session
            </Button>
          </Flex>
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>✨ Your Marketing Magic Awaits! ✨</ModalHeader>
          <ModalBody>
            <Text mb={4}>🎉 Ta-da! Your marketing materials are now ready. Click the button below to view them! 🌟</Text>
            <CircularProgress value={(15 - seconds) * (100 / 15)} color="green.400" size="120px">
              <CircularProgressLabel>{seconds}</CircularProgressLabel>
            </CircularProgress>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleNavigateToMarketingMaterials} colorScheme="blue">
              View Your Marketing Materials
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box mt={8}>
        <Button onClick={handleNavigateToMarketingMaterials} colorScheme="teal">
          View All Marketing Materials
        </Button>
      </Box>
    </Box>
  );
};

export default CreateMarketingComponent;
