// src/Components/CallToAction.js
// src/Components/CallToAction.js
import React from 'react';
import { Box, Heading, SimpleGrid, VStack, Text, Button, Flex, Icon } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

const PricingCard = ({ plan, price, billingCycle, features, coachingInfo }) => (
  <VStack
    bg="white"
    p={8}
    rounded="lg"
    shadow="xl"
    borderWidth={2}
    borderColor="purple.500"
    spacing={6}
    width="full"
    maxW="md"
    mx="auto"
  >
    <Heading as="h3" size="lg" color="gray.800">{plan}</Heading>
    <Text fontSize="4xl" fontWeight="bold" color="purple.600">
      ${price}<span fontSize="xl" color="gray.600">/{billingCycle}</span>
    </Text>
    <Text fontWeight="medium" color="purple.500">Start with a 15-Day Free Trial</Text>
    <VStack align="stretch" spacing={3} width="full">
      {features.map((feature, index) => (
        <Flex key={index} align="center">
          <Icon as={CheckIcon} color="green.500" mr={2} />
          <Text>{feature}</Text>
        </Flex>
      ))}
    </VStack>
    <Box bg="purple.50" p={4} borderRadius="md" width="full">
      <Text fontWeight="bold" color="purple.700" mb={2}>
        Complimentary Coaching Session
      </Text>
      <Text color="gray.600">{coachingInfo}</Text>
    </Box>
    <Link to="/signup" style={{ width: '100%' }}>
      <Button colorScheme="purple" size="lg" width="full">Start Free Trial</Button>
    </Link>
  </VStack>
);

const Testimonial = ({ quote, author, role }) => (
  <Box bg="white" p={6} rounded="lg" shadow="md">
    <Text fontSize="lg" fontStyle="italic" mb={4} color="gray.600">"{quote}"</Text>
    <Text fontWeight="bold" color="gray.800">{author}</Text>
    <Text color="gray.600">{role}</Text>
  </Box>
);

const CallToAction = () => (
  <Box as="section" id="callToAction" py={20} bg="gray.50">
    <Box maxW="container.xl" mx="auto" px={6}>
      <Heading as="h2" size="xl" mb={12} textAlign="center" color="gray.800">
        Simple, Transparent Pricing
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mb={20}>
        <PricingCard
          plan="Monthly Subscription"
          price={7}
          billingCycle="month"
          features={[
            "AI Coach with Personalized Insights",
            "Visual Business Performance Tracking",
            "Tailored Growth Recommendations",
            "AI-Powered Marketing Material Generator",
            "Community Resources",
            "Priority Support"
          ]}
          coachingInfo="Includes a personalized business coaching session to help you succeed!"
        />
        <PricingCard
          plan="Annual Subscription"
          price={65}
          billingCycle="year"
          features={[
            "AI Coach with Personalized Insights",
            "Visual Business Performance Tracking",
            "Tailored Growth Recommendations",
            "AI-Powered Marketing Material Generator",
            "Community Resources",
            "Priority Support"
          ]}
          coachingInfo="Enjoy a one-on-one business coaching session with Michael (a $155 value) to kickstart your success!"
        />
      </SimpleGrid>
      <Heading as="h2" size="xl" mb={12} textAlign="center" color="gray.800">
        What Our Members Say
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <Testimonial
          quote="As far as I know, there's nothing out there like this! The AI coach is seriously like having a genius business partner on call 24/7."
          author="Sarah J."
          role="Hair Stylist"
        />
        <Testimonial
          quote="I am loving this coach! The insights I've gotten have helped how I run my barbershop. Revenue is picking up, and I actually have some free time."
          author="Michael R."
          role="Barber"
        />
        <Testimonial
          quote="The marketing material generator has been a great help. It's saved me a ton of money on design and copywriting."
          author="Emma L."
          role="Makeup Artist"
        />
      </SimpleGrid>
    </Box>
  </Box>
);

export default CallToAction;
