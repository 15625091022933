import React from 'react';
import { Box, VStack, Heading, Divider } from '@chakra-ui/react';
import SquareIntegration from './SquareIntegration';

const Integrations = () => {
  return (
    <Box>
      <Heading size="lg" mb={4}>Integrations</Heading>
      <VStack spacing={6} align="stretch" divider={<Divider />}>
        <SquareIntegration />
        {/* Add other integration components here */}
      </VStack>
    </Box>
  );
};

export default Integrations;
