import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  Input,
  Button,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useToast,
  Icon,
  HStack,
} from '@chakra-ui/react';
import { FaBookOpen, FaTable, FaComments } from 'react-icons/fa';

const DataEntry = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [selectedDate, setSelectedDate] = useState(null);

  const { spreadsheetId, next_row } = location.state || {};
  const [titles, setTitles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentValue, setCurrentValue] = useState('');
  const [dataEntryStatus, setDataEntryStatus] = useState('');
  const [isDataEntryCompleted, setIsDataEntryCompleted] = useState(false);

  console.log('DataEntry component: spreadsheetId:', spreadsheetId);

  const fetchTitles = useCallback(async () => {
    console.log('DataEntry component: Fetching titles for spreadsheetId:', spreadsheetId);
    try {
      const response = await fetch('https://sbscoaches.com/api/get-titles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ spreadsheetId }),
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        console.log('DataEntry component: Titles fetched successfully:', data.titles);
        setTitles(data.titles || []);
      } else {
        const data = await response.json();
        throw new Error(data.error || 'Failed to fetch titles');
      }
    } catch (error) {
      console.error('DataEntry component: Error fetching titles:', error);
      setDataEntryStatus('Failed to fetch titles. Please try again.');
      toast({
        title: 'Error',
        description: 'Failed to fetch titles. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [spreadsheetId, toast]);

  useEffect(() => {
    if (spreadsheetId) {
      console.log('DataEntry component: Fetching titles because spreadsheetId exists');
      fetchTitles();
    }
  }, [spreadsheetId, fetchTitles]);

  const handleSubmit = async () => {
    console.log('DataEntry component: Submitting entry for title:', titles[currentIndex]);
    try {
      const value = titles[currentIndex].toLowerCase() === "date"
        ? selectedDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
        : currentValue;

      const response = await fetch('https://sbscoaches.com/api/submit-entry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ spreadsheetId, title: titles[currentIndex], value, row_index: next_row }),
        credentials: 'include',
      });

      if (response.ok) {
        console.log('DataEntry component: Entry submitted successfully for:', titles[currentIndex]);
        setCurrentValue('');
        setSelectedDate(null);
        if (currentIndex === titles.length - 1) {
          setIsDataEntryCompleted(true);
          onOpen();
          console.log('DataEntry component: Data entry completed');
        } else {
          setCurrentIndex(prevIndex => prevIndex + 1);
        }
      } else {
        const data = await response.json();
        throw new Error(data.error || 'Failed to submit the entry');
      }
    } catch (error) {
      console.error('DataEntry component: Error submitting the entry:', error);
      setDataEntryStatus('Failed to submit the entry. Please try again.');
      toast({
        title: 'Error',
        description: 'Failed to submit the entry. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCancel = () => {
    navigate('/user-profile');
  };

  const handleNewClientDataEntry = () => {
    navigate('/user-profile');
  };

  const handleNavigateToCoachingFeedback = () => {
    navigate('/coach-feedback');
  };

  const handleViewSpreadsheet = async () => {
    try {
      const response = await fetch('https://sbscoaches.com/api/open_spreadsheet', {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        window.open(data.spreadsheet_url, '_blank');
      } else {
        const errorData = await response.json();
        console.error('Failed to open spreadsheet:', errorData.error);
        // Show an error message to the user
      }
    } catch (error) {
      console.error('Error opening spreadsheet:', error);
      // Show an error message to the user
    }
  };

  useEffect(() => {
    if (!spreadsheetId) {
      navigate('/user-profile');
    }
  }, [spreadsheetId, navigate]);

  return (
    <Box maxWidth="800px" margin="0 auto" p={4}>
      <Box textAlign="center">
        <Icon as={FaBookOpen} w={10} h={10} mb={4} color="blue.500" />
        <Heading as="h3" size="lg" mb={4}>
          Streamline Your Business
        </Heading>
        <Text fontSize="lg" mb={8}>
          Quickly enter your numbers below to gain valuable insights and effortlessly manage your client interactions.
        </Text>
      </Box>
      <Heading as="h2" size="xl" mb={6} textAlign="center">
        Client Visit Details
      </Heading>
      <Text fontSize="xl" mb={4} textAlign="center" fontWeight="bold">
        Record Your Performance
      </Text>
      <Text fontSize="lg" mb={4} textAlign="center">
        Simply input your key numbers to keep track of your performance and client progress. You can enter numbers for any date range—today, a past week, a past month, or any other time period that suits your needs. Our system will accurately register the data for the specific dates you provide.
      </Text>
      <Text fontSize="md" mb={2} textAlign="center">
        🌞 <strong>Daily</strong>: After your last appointment of the day.
      </Text>
      <Text fontSize="md" mb={2} textAlign="center">
        📅 <strong>Weekly</strong>: After your last appointment of the week.
      </Text>
      <Text fontSize="md" mb={2} textAlign="center">
        📆 <strong>Monthly</strong>: After your last working day of the month.
      </Text>
      <Text fontSize="lg" mb={4} textAlign="center">
        Let's get those numbers in! 🚀
      </Text>
      <Text fontSize="lg" mb={4} textAlign="center">
        When entering data for "Date", you can use it to represent the date range for your numbers—whether it's for today, a past week, a past month, or any other time period. The spreadsheet will understand and register your entries accurately.
      </Text>
      <Text fontSize="lg" mb={4} textAlign="center">
        Does that make sense? If not, reach out to our support team for help! 💬
      </Text>
      <Box textAlign="center" mb={4}>
        <Button
          as={RouterLink}
          to="/contact"
          colorScheme="teal"
          size="md"
          leftIcon={<Icon as={FaComments} />}
        >
          Click Here for Support
        </Button>
      </Box>
      {dataEntryStatus && (
        <Text color="red.500" mt={4}>
          {dataEntryStatus}
        </Text>
      )}

      {isDataEntryCompleted ? (
        <>
          <Text fontSize="xl" mb={4}>
            Data entry completed!
          </Text>
          <Button colorScheme="blue" onClick={handleNewClientDataEntry}>
            Enter Numbers for New Client
          </Button>
        </>
      ) : (
        <>
          {currentIndex < titles.length && (
            <Stack spacing={6}>
              <Text fontSize="xl">
                Enter '{titles[currentIndex]}':
              </Text>
              {titles[currentIndex].toLowerCase() === "date" ? (
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Select a date"
                />
              ) : (
                <Input
                  size="lg"
                  value={currentValue}
                  onChange={(e) => setCurrentValue(e.target.value)}
                  placeholder="Enter the value"
                />
              )}
              <Button colorScheme="blue" size="lg" onClick={handleSubmit}>
                Submit
              </Button>
            </Stack>
          )}

          {/* New Spreadsheet Pro Tip box */}
          <Box
            bg="yellow.100"
            p={4}
            borderRadius="md"
            mt={8}
            mb={4}
            border="2px dashed"
            borderColor="yellow.400"
          >
            <Text fontSize="md" textAlign="center" color="gray.700">
              🖥️ Spreadsheet Pro Tip! 📱
            </Text>
            <Text fontSize="sm" textAlign="center" mt={2} color="gray.600">
              Oops! Made a boo-boo? No worries! For easy-peasy editing, hop onto your laptop or desktop. It's like having a magic eraser for your spreadsheet! ✨🖱️
            </Text>
          </Box>

          <HStack spacing={4} justifyContent="center" mt={8}>
            <Button
              leftIcon={<Icon as={FaTable} />}
              colorScheme="teal"
              size="lg"
              onClick={handleViewSpreadsheet}
            >
              View/Edit Spreadsheet
            </Button>
            <Button colorScheme="gray" size="lg" onClick={handleCancel}>
              Cancel
            </Button>
          </HStack>
        </>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Number Entry Completed</ModalHeader>
          <ModalBody>
            <Text>Congratulations! You have successfully completed the data entry.</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleNavigateToCoachingFeedback}>
              Get Coaching Feedback
            </Button>
            <Button colorScheme="teal" onClick={handleViewSpreadsheet}>
              View Spreadsheet
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DataEntry;
