import React from 'react';
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Heading,
  VStack,
  Button,
  useColorModeValue,
  Icon,
  Text,
  useToast,
} from '@chakra-ui/react';
import { FaCreditCard, FaLock, FaCalendar } from 'react-icons/fa';
import ResetPassword from './ResetPassword'; // Import the reset password component
import ScheduleCoaching from './ScheduleCoaching';
import { useAuth } from '../context/AuthContext'; // Import the authentication context

const AccountSettings = () => {
  const { isAuthenticated } = useAuth(); // Get authentication status
  const toast = useToast();
  const bg = useColorModeValue('purple.50', 'purple.900');
  const color = useColorModeValue('gray.800', 'white');
  const tabBg = useColorModeValue('white', 'gray.800');
  const iconColor = useColorModeValue('purple.500', 'purple.300');

  const handleManageSubscription = async () => {
    if (!isAuthenticated) {
      toast({
        title: 'Unauthorized',
        description: 'You must be logged in to manage your subscription.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await fetch('https://sbscoaches.com/api/customer-portal', { method: 'POST', credentials: 'include' });
      const data = await response.json();
      if (response.ok) {
        window.location.href = data.url;
      } else {
        throw new Error(data.error || 'Failed to access the customer portal.');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to access the customer portal.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handlePayment = () => {
    if (!isAuthenticated) {
      toast({
        title: 'Unauthorized',
        description: 'You must be logged in to schedule a coaching session.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    window.location.href = 'https://book.stripe.com/bIY00Ybsk7Ew14Q6ox';
  };

  return (
    <Box bg={bg} p={8} borderRadius="lg" boxShadow="lg" w="full" maxW="md" mx="auto" textAlign="center">
      <Heading mb={4} color={color}>Account Settings</Heading>
      <Tabs isFitted variant="enclosed" colorScheme="purple">
        <TabList mb="1em">
          <Tab _selected={{ color: "purple.500", bg: tabBg }}>Change Password</Tab>
          <Tab _selected={{ color: "purple.500", bg: tabBg }}>Manage Subscription</Tab>
          <Tab _selected={{ color: "purple.500", bg: tabBg }}>Coaching Sessions</Tab>
        </TabList>

        <TabPanels>
          {/* Change Password */}
          <TabPanel>
            <VStack spacing={4}>
              <Icon as={FaLock} w={10} h={10} color={iconColor} />
              <Text color={color}>Change your account password</Text>
              <ResetPassword /> {/* Include ResetPassword Component */}
            </VStack>
          </TabPanel>

          {/* Manage Subscription */}
          <TabPanel>
            <VStack spacing={4}>
              <Icon as={FaCreditCard} w={10} h={10} color={iconColor} />
              <Text color={color}>Manage and customize your subscription options</Text>
              <Button
                colorScheme="purple"
                size="lg"
                onClick={handleManageSubscription}
                width="full"
              >
                Manage Subscription
              </Button>
            </VStack>
          </TabPanel>

          {/* Coaching Sessions */}
          <TabPanel>
            <VStack spacing={4}>
              <Icon as={FaCalendar} w={10} h={10} color={iconColor} />
              <Text color={color}>Schedule your coaching sessions</Text>
              <ScheduleCoaching calendlyUrl="https://calendly.com/saloncoach100/live-business-coaching-session" />
              <Button
                colorScheme="purple"
                size="lg"
                onClick={handlePayment}
                width="full"
              >
                Schedule a Coaching Session
              </Button>
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default AccountSettings;
