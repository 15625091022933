// Component for compact performance summaries
import React from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';

const PerformanceSummary = ({ data, period }) => {
  console.log(`Rendering PerformanceSummary for ${period}:`, data); // Log to ensure correct data

  if (!data) {
    return <Text>No performance data available for {period}</Text>;
  }

  const totalItemPayments = data?.total_item_payments ? data.total_item_payments.toFixed(2) : '0.00';
  const totalClients = data?.total_clients || 0;

  return (
    <VStack align="stretch">
      <Box>
        <Text fontSize="lg">Total Item Payments: ${totalItemPayments}</Text>
        <Text fontSize="lg">Total Clients: {totalClients}</Text>
      </Box>
    </VStack>
  );
};

export default PerformanceSummary;
