import React, { useState, useEffect } from 'react';
import {
  VStack, Heading, Text, Box, Flex, Icon,
  CircularProgress, CircularProgressLabel,
} from '@chakra-ui/react';
import { FaChartLine, FaCheck } from 'react-icons/fa';
import { motion } from 'framer-motion';
import TrendAnalyzer from './TrendAnalyzer';

const MotionBox = motion(Box);

const ThirtySecondBoost = ({ businessCategory, onComplete }) => {
  const [overallProgress, setOverallProgress] = useState(0);
  const [analyzedTrends, setAnalyzedTrends] = useState(null);
  const [isFetchingComplete, setIsFetchingComplete] = useState(false);

  useEffect(() => {
    // Start the timer on mount
    const timer = setInterval(() => {
      setOverallProgress(prev => {
        const newProgress = prev + 100 / 30; // 30 seconds total
        return newProgress >= 100 ? 100 : newProgress;
      });
    }, 1000); // Update every second

    // Clear the timer when component unmounts or when progress completes
    return () => clearInterval(timer);
  }, []); // Removed isFetchingComplete from dependency array

  useEffect(() => {
    // Only trigger onComplete when the timer reaches 100% (30 seconds)
    if (overallProgress >= 100) {
      onComplete(analyzedTrends);
    }
  }, [overallProgress, onComplete, analyzedTrends]);

  const handleTrendAnalysisComplete = (result) => {
    setAnalyzedTrends(result);
    setIsFetchingComplete(true); // Set fetching complete to show checkmark
  };

  return (
    <VStack spacing={6} align="stretch" w="full">
      <Heading size="md" textAlign="center" color="teal.600">30-Second Boost</Heading>
      <CircularProgress value={overallProgress} size="120px" thickness="8px" color="teal.400">
        <CircularProgressLabel>{Math.round(overallProgress)}%</CircularProgressLabel>
      </CircularProgress>
      <VStack spacing={4} align="stretch">
        <MotionBox
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Flex align="center" bg="teal.50" p={3} borderRadius="md">
            <Box bg="teal.100" p={2} borderRadius="full" mr={3}>
              <Icon as={isFetchingComplete ? FaCheck : FaChartLine} color="teal.500" boxSize={5} />
            </Box>
            <Box flex={1}>
              <Text fontWeight="bold" fontSize="sm">Analyze Local Trends</Text>
              <TrendAnalyzer
                businessCategory={businessCategory}
                onComplete={handleTrendAnalysisComplete}
              />
            </Box>
          </Flex>
        </MotionBox>
      </VStack>
    </VStack>
  );
};

export default ThirtySecondBoost;
