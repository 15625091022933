// src/Components/Features.js
import React from 'react';
import { Box, Heading, SimpleGrid, VStack, Text, Icon } from '@chakra-ui/react';
import { ChatIcon, StarIcon, CalendarIcon, ViewIcon, BellIcon, PlusSquareIcon } from '@chakra-ui/icons';

const FeatureCard = ({ IconComponent, title, description }) => (
  <VStack
    bg="white"
    p={6}
    rounded="lg"
    shadow="md"
    transition="all 0.3s"
    _hover={{ shadow: 'lg', transform: 'translateY(-4px)' }}
  >
    <Icon as={IconComponent} boxSize="48px" color="purple.500" />
    <Heading as="h3" size="md" textAlign="center">{title}</Heading>
    <Text color="gray.600" textAlign="center">{description}</Text>
  </VStack>
);

const Features = () => (
  <Box as="section" id="features" py={20} bg="gray.50">
    <Box maxW="container.xl" mx="auto" px={6}>
      <Heading as="h2" size="xl" mb={12} textAlign="center" color="gray.800">
        Empower Your Professional Career
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
        <FeatureCard
          IconComponent={ChatIcon}
          title="AI Coach with Personalized Insights"
          description="Get 24/7 business advice and support from our AI coach, tailored to your unique salon or spa needs."
        />
        <FeatureCard
          IconComponent={StarIcon}
          title="Visual Business Performance Tracking"
          description="Track your growth with easy-to-understand graphs and make data-driven decisions for your business."
        />
        <FeatureCard
          IconComponent={CalendarIcon}
          title="Tailored Growth Recommendations"
          description="Receive customized strategies to boost your business based on your specific goals and market trends."
        />
        <FeatureCard
          IconComponent={PlusSquareIcon}
          title="AI-Powered Marketing Material Generator"
          description="Create professional marketing content effortlessly, saving time and money on design and copywriting."
        />
        <FeatureCard
          IconComponent={ViewIcon}
          title="Community Resources"
          description="Access a wealth of resources and connect with fellow professionals to share insights and inspiration."
        />
        <FeatureCard
          IconComponent={BellIcon}
          title="Priority Support"
          description="Get quick assistance whenever you need it, ensuring smooth operation of your business."
        />
      </SimpleGrid>
    </Box>
  </Box>
);

export default Features;
