import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Textarea,
  Button,
  useToast,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Spinner,
} from '@chakra-ui/react';
import { useAuth } from '../context/AuthContext';

const ProfileSettings = ({ userProfile, onSave }) => {
  const [profile, setProfile] = useState(userProfile || {});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const toast = useToast();
  const { isAuthenticated } = useAuth(); // Get the authentication state
  
  useEffect(() => {
    if (userProfile) {
      setProfile(userProfile);
    }
  }, [userProfile]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile(prevProfile => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    if (!isAuthenticated) {
      toast({
        title: "Unauthorized",
        description: "You must be logged in to update profile settings.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    setIsLoading(true);
    setError(null);
  
    try {
      const response = await axios.put('https://sbscoaches.com/api/update_profile_settings', profile, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true, // Ensures session cookies are sent
      });
  
      if (response.status === 200) {
        onSave(profile);
        toast({
          title: "Profile updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error('Failed to update profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setError(error.message || 'An unexpected error occurred');
      toast({
        title: "Error updating profile",
        description: error.message || 'An unexpected error occurred',
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <Box maxWidth="600px" margin="auto" p={5}>
      <VStack spacing={6} align="stretch">
        <Heading size="lg">Profile Settings</Heading>
        <Text fontSize="sm" color="gray.600">
          The more information you provide, the better our AI can assist you with personalized coaching and social media content. All fields are optional, but filling them out will enhance your experience.
        </Text>

        {isLoading && <Spinner size="lg" />}
        {error && <Text color="red.500">{error}</Text>}

        <FormControl>
          <FormLabel>Services Offered</FormLabel>
          <Textarea
            name="services"
            value={profile.services || ''}
            onChange={handleChange}
            placeholder="e.g., Haircuts, Color, Styling, Extensions, etc."
          />
        </FormControl>

        <FormControl>
          <FormLabel>Products Used</FormLabel>
          <Textarea
            name="products"
            value={profile.products || ''}
            onChange={handleChange}
            placeholder="e.g., Olaplex, Redken, Kerastase, etc."
          />
        </FormControl>

        <FormControl>
          <FormLabel>Years of Experience</FormLabel>
          <Input
            name="years_experience"
            type="number"
            value={profile.years_experience || ''}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Target Audience</FormLabel>
          <Textarea
            name="target_audience"
            value={profile.target_audience || ''}
            onChange={handleChange}
            placeholder="e.g., Women 25-45, professionals, eco-conscious consumers, etc."
          />
        </FormControl>

        <FormControl>
          <FormLabel>Instagram Handle</FormLabel>
          <InputGroup>
            <InputLeftAddon children="@" />
            <Input
              name="instagram_handle"
              value={profile.instagram_handle || ''}
              onChange={handleChange}
            />
          </InputGroup>
        </FormControl>

        <FormControl>
          <FormLabel>Facebook Username</FormLabel>
          <InputGroup>
            <InputLeftAddon children="facebook.com/" />
            <Input
              name="facebook_username"
              value={profile.facebook_username || ''}
              onChange={handleChange}
            />
          </InputGroup>
        </FormControl>

        <FormControl>
          <FormLabel>TikTok Handle</FormLabel>
          <InputGroup>
            <InputLeftAddon children="@" />
            <Input
              name="tiktok_handle"
              value={profile.tiktok_handle || ''}
              onChange={handleChange}
            />
          </InputGroup>
        </FormControl>

        <Button
          colorScheme="blue"
          size="lg"
          onClick={handleSave}
          isLoading={isLoading}
          loadingText="Saving..."
        >
          Save Changes
        </Button>
      </VStack>
    </Box>
  );
};

export default ProfileSettings;
