// Reusable chart component for performance data
import React from 'react';
import { Box } from '@chakra-ui/react';
import { Bar } from 'react-chartjs-2';

const PerformanceChart = ({ data, period }) => {
  console.log(`Rendering PerformanceChart for ${period}:`, data); // Log for debugging

  // Ensure the component does not render if data is missing
  if (!data || data.total_item_payments === undefined || data.total_clients === undefined) {
    return <p>No chart data available for {period}</p>;
  }

  // Data for Item Payments Chart
  const chartDataItemPayments = {
    labels: ['Item Payments'],
    datasets: [{
      label: `${period.charAt(0).toUpperCase() + period.slice(1)} Item Payments`,
      data: [data.total_item_payments],
      backgroundColor: ['rgba(54, 162, 235, 0.6)'],
    }]
  };

  // Data for Total Clients Chart
  const chartDataTotalClients = {
    labels: ['Total Clients'],
    datasets: [{
      label: `${period.charAt(0).toUpperCase() + period.slice(1)} Total Clients`,
      data: [data.total_clients],
      backgroundColor: ['rgba(255, 99, 132, 0.6)'],
    }]
  };

  // Chart options for Item Payments
  const optionsItemPayments = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `$${value.toFixed(2)}`, // Display as currency
        }
      }
    }
  };

  // Chart options for Total Clients
  const optionsClients = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `${value}`, // Display as number
        }
      }
    }
  };

  return (
    <Box>
      {/* Chart for Item Payments */}
      <Box h="300px" mb={4}>
        <Bar data={chartDataItemPayments} options={optionsItemPayments} />
      </Box>
      {/* Chart for Total Clients */}
      <Box h="300px">
        <Bar data={chartDataTotalClients} options={optionsClients} />
      </Box>
    </Box>
  );
};

export default PerformanceChart;
