import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, VStack, HStack, Progress, Select } from '@chakra-ui/react';

const PeerComparison = () => {
  const [businessCategory, setBusinessCategory] = useState('');
  const [peerData, setPeerData] = useState({});
  const [timeframe, setTimeframe] = useState('weekly');

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch('https://sbscoaches.com/api/user_info', {
          credentials: 'include',
        });
        const data = await response.json();
        if (response.ok) {
          setBusinessCategory(data.business_category || '');
        } else {
          throw new Error(data.error || 'Failed to fetch user info');
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    const fetchPeerData = async () => {
      if (!businessCategory) return;

      try {
        const response = await fetch('https://sbscoaches.com/api/peer-comparison', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ businessCategory, timeframe }),
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          setPeerData(data);
        } else {
          throw new Error('Failed to fetch peer comparison data');
        }
      } catch (error) {
        console.error('Error fetching peer data:', error);
      }
    };

    fetchPeerData();
  }, [businessCategory, timeframe]);

  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" p={4}>
      <Heading size="md" mb={4}>How You Compare to Peers in {businessCategory}</Heading>
      <Select value={timeframe} onChange={handleTimeframeChange} mb={4}>
        <option value="weekly">Weekly</option>
        <option value="monthly">Monthly</option>
      </Select>
      <VStack spacing={4} align="stretch">
        {Object.entries(peerData).map(([metric, data]) => (
          <Box key={metric}>
            <Text fontWeight="bold">{metric}</Text>
            <HStack spacing={4}>
              <Progress 
                value={data.percentile} 
                size="sm" 
                width="70%" 
                colorScheme={data.percentile > 50 ? "green" : "orange"}
              />
              <Text>{data.percentile}th percentile</Text>
            </HStack>
            <Text fontSize="sm">Peer average: {data.average}</Text>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default PeerComparison;
