import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Box, Button, Text, VStack, useToast, Badge, Flex, Spinner } from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import SquarePermissions from './SquarePermissions';

const SquareIntegration = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [connecting, setConnecting] = useState(false);
  const [loadingPermissions, setLoadingPermissions] = useState(false);
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();

  const checkSquareConnection = useCallback(async () => {
    try {
      const response = await axios.get('https://sbscoaches.com/api/user_info', {
        withCredentials: true,
      });
      console.log('User info response:', response.data);
      setIsConnected(response.data.is_connected_to_square);
    } catch (error) {
      console.error('Error checking Square connection:', error);
      toast({
        title: 'Error',
        description: 'Failed to check Square connection status.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    checkSquareConnection();
  }, [checkSquareConnection]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    const state = searchParams.get('state');

    // Log the state and code for debugging purposes
    console.log('Received state:', state);
    console.log('Received code:', code);

    if (code && state) {
      completeSquareConnection(code, state);
    }
  }, [location]);

  const handleConnect = async () => {
    setConnecting(true);
    setLoadingPermissions(true);
    try {
      const response = await axios.get('https://sbscoaches.com/api/initiate-square-oauth', {
        withCredentials: true,
      });
      if (response.data.auth_url) {
        // Redirect user to the Square authorization page
        window.location.href = response.data.auth_url;
      } else {
        throw new Error('No authorization URL received from the server');
      }
    } catch (error) {
      console.error('Error initiating Square OAuth:', error);
      toast({
        title: 'Error',
        description: 'Failed to initiate Square connection. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setConnecting(false);
      setLoadingPermissions(false);
    }
  };

  const completeSquareConnection = async (code, state) => {
    setConnecting(true);
    setLoadingPermissions(true);
    try {
      const response = await axios.post('https://sbscoaches.com/api/complete-square-oauth',
        { code, state },
        { withCredentials: true }
      );
      if (response.data.success) {
        await checkSquareConnection();
        toast({
          title: 'Success',
          description: 'Connected to Square successfully!',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error(response.data.error || 'Failed to complete Square connection');
      }
    } catch (error) {
      console.error('Error completing Square connection:', error);
      toast({
        title: 'Error',
        description: error.response?.data?.error || 'Failed to connect to Square. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setConnecting(false);
      setLoadingPermissions(false);
      navigate('/integrations', { replace: true });
    }
  };

  const handleDisconnect = async () => {
    setConnecting(true);
    try {
      const response = await axios.post('https://sbscoaches.com/api/disconnect-square', {}, {
        withCredentials: true,
      });
      if (response.data.message) {
        await checkSquareConnection();
        toast({
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error disconnecting Square:', error);
      console.error('Error response:', error.response?.data);
      toast({
        title: 'Error',
        description: error.response?.data?.error || 'Failed to disconnect Square. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setConnecting(false);
    }
  };

  if (loading) {
    return <Text>Loading...</Text>;
  }

  return (
    <VStack spacing={4} align="stretch">
      <Text fontSize="xl" fontWeight="bold">Square Integration</Text>
      <Box borderWidth={1} borderRadius="lg" p={4}>
        <VStack spacing={3} align="stretch">
          <Flex justify="space-between" align="center">
            <Text>Connection Status:</Text>
            {isConnected ? (
              <Badge colorScheme="green">Connected <CheckCircleIcon ml={1} /></Badge>
            ) : (
              <Badge colorScheme="red">Not Connected <WarningIcon ml={1} /></Badge>
            )}
          </Flex>
          {isConnected ? (
            <>
              <Button colorScheme="red" onClick={handleDisconnect} isLoading={connecting}>
                Disconnect Square Account
              </Button>
              {loadingPermissions ? (
                <Spinner />
              ) : (
                <SquarePermissions />
              )}
            </>
          ) : (
            <Button colorScheme="blue" onClick={handleConnect} isLoading={connecting}>
              Connect Square Account
            </Button>
          )}
        </VStack>
      </Box>
    </VStack>
  );
};

export default SquareIntegration;
