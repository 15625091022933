import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Text, List, ListItem, Spinner } from '@chakra-ui/react';

const SquarePermissions = () => {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPermissions = async () => {
      setLoading(true); // Set loading state before fetching
      try {
        const response = await axios.get('https://sbscoaches.com/api/square-permissions', {
          withCredentials: true,
        });
        console.log('Permissions response:', response.data.permissions); // Log permissions for debugging
        setPermissions(response.data.permissions);
      } catch (error) {
        console.error('Error fetching Square permissions:', error);
        setError(error.response?.data?.error || 'Failed to fetch Square permissions.');
      } finally {
        setLoading(false); // End loading state
      }
    };

    fetchPermissions();
  }, []);

  if (loading) return <Spinner label="Loading Square permissions..." />;
  if (error) return <Text color="red.500">{error}</Text>;

  return (
    <Box mt={4}>
      <Text fontSize="lg" fontWeight="bold">Square Permissions:</Text>
      {permissions.length > 0 ? (
        <List spacing={2} mt={2}>
          {permissions.map((permission, index) => (
            <ListItem key={index}>{permission}</ListItem>
          ))}
        </List>
      ) : (
        <Text>No permissions found.</Text>
      )}
    </Box>
  );
};

export default SquarePermissions;
