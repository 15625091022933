import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  VStack,
  Input,
  Button,
  Text,
  Flex,
  Icon,
  useColorModeValue,
  keyframes,
  useToast,
  Heading,
  useMediaQuery,
} from '@chakra-ui/react';
import { FaRobot, FaUser, FaMagic, FaStar, FaComments } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';

const pulseAnimation = keyframes`
  0% { box-shadow: 0 0 0 0px rgba(29, 161, 242, 0.7); }
  100% { box-shadow: 0 0 0 20px rgba(29, 161, 242, 0); }
`;

const API_URL = process.env.REACT_APP_API_URL || 'https://sbscoaches.com/api';

const ChatComponent = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const chatEndRef = useRef(null);
  const toast = useToast();
  const [isMobile] = useMediaQuery("(max-width: 480px)");

  const bgColor = useColorModeValue('white', 'gray.800');
  const msgBgColor = useColorModeValue('gray.100', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const gradientBg = "linear(to-r, purple.500, pink.500, white)";

  // Use the AuthContext to get the user data
  const { user } = useAuth();
  const businessCategory = user?.business_category || 'beauty business';

  // Step 1: Add console.log statements here
  console.log('User:', user);
  console.log('Business Category:', businessCategory);

  // Step 2: Update the useEffect hook
  useEffect(() => {
    console.log('Setting messages with business category:', businessCategory);
    setMessages([
      {
        role: 'assistant',
        content: `Welcome to your personal ${businessCategory} coaching session! I'm here to help you improve your business through focused dialogue. What specific aspect of your ${businessCategory} business would you like to explore today?`
      }
    ]);
  }, [businessCategory]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = async () => {
    if (inputMessage.trim()) {
      const newMessage = { role: 'user', content: inputMessage };
      setMessages([...messages, newMessage]);
      setInputMessage('');
      setIsLoading(true);

      try {
        const response = await axios.post(`${API_URL}/chat`, {
          messages: [...messages, newMessage],
          businessCategory: businessCategory
        }, {
          withCredentials: true
        });

        const aiResponse = { role: 'assistant', content: response.data.message };
        setMessages(prevMessages => [...prevMessages, aiResponse]);
      } catch (error) {
        console.error('Error sending message:', error);
        toast({
          title: 'Error',
          description: 'Failed to get response from the AI.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const renderIcon = (role) => {
    if (role === 'user') return <Icon as={FaUser} w={isMobile ? 4 : 6} h={isMobile ? 4 : 6} color="blue.500" />;
    return <Icon as={FaRobot} w={isMobile ? 4 : 6} h={isMobile ? 4 : 6} color="cyan.500" />;
  };

  return (
    <Box w="100%" h="100%" bg={bgColor} borderRadius="lg" overflow="hidden" boxShadow="xl" position="relative">
      <Flex
        alignItems="center"
        justifyContent="center"
        bgGradient={gradientBg}
        p={isMobile ? 3 : 4}
        borderBottomWidth={1}
        borderColor="purple.600"
      >
        <Icon as={FaComments} w={isMobile ? 6 : 8} h={isMobile ? 6 : 8} color="white" mr={2} />
        <Heading size={isMobile ? "md" : "lg"} color="white">Your AI SBS Coach</Heading>
      </Flex>
      <VStack h={`calc(100% - ${isMobile ? '60px' : '70px'})`} spacing={isMobile ? 3 : 4} p={isMobile ? 3 : 4}>
        <Box flex={1} w="full" overflowY="auto">
          {messages.map((msg, index) => (
            <Flex
              key={index}
              justify={msg.role === 'user' ? 'flex-end' : 'flex-start'}
              mb={isMobile ? 3 : 4}
            >
              <Box
                maxW={isMobile ? "80%" : "70%"}
                bg={msg.role === 'user' ? 'purple.500' : msgBgColor}
                color={msg.role === 'user' ? 'white' : textColor}
                borderRadius="lg"
                px={isMobile ? 2 : 3}
                py={isMobile ? 1 : 2}
                animation={`${pulseAnimation} 1s ease-out`}
              >
                <Flex align="center" mb={1}>
                  {renderIcon(msg.role)}
                  <Text ml={2} fontWeight="bold" fontSize={isMobile ? "sm" : "md"}>  
                    {msg.role === 'user' ? 'You' : 'AI Coach'}
                  </Text>
                </Flex>
                <Text fontSize={isMobile ? "sm" : "md"}>{msg.content}</Text>
              </Box>
            </Flex>
          ))}
          <div ref={chatEndRef} />
        </Box>
        <Flex w="full">
          <Input
            flex={1}
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Ask your AI coach..."
            borderRadius="full"
            mr={2}
            size={isMobile ? "sm" : "md"}
            _focus={{ borderColor: 'purple.500', boxShadow: '0 0 0 1px purple.500' }}
          />
          <Button
            onClick={handleSendMessage}
            bgGradient={gradientBg}
            color="white"
            borderRadius="full"
            _hover={{ bgGradient: "linear(to-r, purple.600, pink.600, gray.100)" }}
            isLoading={isLoading}
            size={isMobile ? "sm" : "md"}
          >
            <Icon as={FaMagic} />
          </Button>
        </Flex>
      </VStack>
      <Icon
        as={FaStar}
        position="absolute"
        top={isMobile ? 1 : 2}
        right={isMobile ? 1 : 2}
        color="yellow.400"
        w={isMobile ? 4 : 6}
        h={isMobile ? 4 : 6}
      />
    </Box>
  );
};

export default ChatComponent;
