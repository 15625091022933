import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Heading, Text, Button, Spinner, Flex, Icon, useToast } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { FaChartLine } from 'react-icons/fa';
import { Bar, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import ScheduleCoaching from './ScheduleCoaching';

const CoachFeedback = () => {
  const toast = useToast();
  const [coachFeedback, setCoachFeedback] = useState('');
  const [chartData, setChartData] = useState({});
  const [businessCategory, setBusinessCategory] = useState('');
  const [isPaid, setIsPaid] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch('https://sbscoaches.com/api/user_info', {
          credentials: 'include',
        });
        const data = await response.json();
        if (response.ok) {
          setBusinessCategory(data.business_category || '');
        } else {
          throw new Error(data.error || 'Failed to fetch user info');
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    const fetchCoachFeedback = async () => {
      try {
        const response = await fetch('https://sbscoaches.com/api/get-coach-feedback', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ businessCategory }),
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setCoachFeedback(data.feedback || '');
          setChartData(data.chartData || {});
        } else {
          const data = await response.json();
          throw new Error(data.error || 'Failed to get coach feedback');
        }
      } catch (error) {
        console.error('Error getting coach feedback:', error);
        setCoachFeedback('Failed to get coach feedback. Please try again.');
      }
    };

    if (businessCategory) {
      fetchCoachFeedback();
    }
  }, [businessCategory]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get('payment') === 'success') {
      setIsPaid(true);
    }
  }, [location.search]);

  const handleShare = (platform) => {
    const url = encodeURIComponent("https://sbscoaches.com");
    const text = encodeURIComponent("Check out my business performance insights from SBS Coaches!");

    switch (platform) {
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank', 'width=600,height=400');
        break;
      case 'instagram':
      case 'copy':
        navigator.clipboard.writeText("https://sbscoaches.com").then(() => {
          toast({
            title: platform === 'instagram' ? "Link copied for Instagram!" : "Link copied!",
            description: platform === 'instagram' ? "Paste this link in your Instagram post or story." : "You can now paste this link anywhere.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        });
        break;
      default:
        return;
    }
  };

  const handleBackToProfile = () => {
    navigate('/user-profile');
  };

  const handlePayment = () => {
    window.location.href = 'https://book.stripe.com/bIY00Ybsk7Ew14Q6ox';
  };

  const serviceRevenueData = {
    labels: chartData.startDates || [],
    datasets: [
      {
        label: 'Total Service Revenue',
        data: chartData.serviceRevenue || [],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
    ],
  };

  const retailRevenueData = {
    labels: chartData.startDates || [],
    datasets: [
      {
        label: 'Total Retail Revenue',
        data: chartData.retailRevenue || [],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const totalClientsData = {
    labels: chartData.startDates || [],
    datasets: [
      {
        label: 'Total Clients',
        data: chartData.totalClients || [],
        fill: false,
        borderColor: 'rgba(153, 102, 255, 0.6)',
        tension: 0.1,
      },
    ],
  };

  const avgServiceTicketData = {
    labels: chartData.startDates || [],
    datasets: [
      {
        label: 'Average Service Ticket',
        data: chartData.avgServiceTicket || [],
        backgroundColor: 'rgba(255, 159, 64, 0.6)',
      },
    ],
  };

  const avgRetailTicketData = {
    labels: chartData.startDates || [],
    datasets: [
      {
        label: 'Average Retail Ticket',
        data: chartData.avgRetailTicket || [],
        backgroundColor: 'rgba(255, 205, 86, 0.6)',
      },
    ],
  };

  const retailToServicePercentageData = {
    labels: chartData.startDates || [],
    datasets: [
      {
        label: 'Retail to Service (%)',
        data: chartData.retailToServicePercentage || [],
        fill: false,
        borderColor: 'rgba(255, 99, 132, 0.6)',
        tension: 0.1,
      },
    ],
  };

  return (
    <Box>
      <Button
        leftIcon={<ArrowBackIcon />}
        variant="ghost"
        size="sm"
        mb={4}
        onClick={handleBackToProfile}
      >
        Back to Profile
      </Button>
      <Box textAlign="center" mb={4}>
        <Icon as={FaChartLine} w={10} h={10} mb={4} color="blue.500" />
        <Heading as="h2" size="xl" mb={4}>
          Hey Coach, How's My Game?
        </Heading>
      </Box>
      {coachFeedback ? (
        <>
          <Text>
            {coachFeedback.split('\n').map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </Text>
          <Box mt={6} overflowX="scroll">
            <Bar data={serviceRevenueData} />
          </Box>
          <Box mt={6} overflowX="scroll">
            <Bar data={retailRevenueData} />
          </Box>
          <Box mt={6} overflowX="scroll">
            <Line data={totalClientsData} />
          </Box>
          <Box mt={6} overflowX="scroll">
            <Bar data={avgServiceTicketData} />
          </Box>
          <Box mt={6} overflowX="scroll">
            <Bar data={avgRetailTicketData} />
          </Box>
          <Box mt={6} overflowX="scroll">
            <Line data={retailToServicePercentageData} />
          </Box>
          {isPaid ? (
            <ScheduleCoaching calendlyUrl="https://calendly.com/saloncoach100/live-business-coaching-session" />
          ) : (
            <Flex alignItems="center" justifyContent="center" height="200px">
              <Button colorScheme="blue" size="lg" onClick={handlePayment}>
                Schedule a Coaching Session
              </Button>
            </Flex>
          )}
        </>
      ) : (
        <Flex alignItems="center" justifyContent="center" height="200px">
          <Spinner size="xl" color="blue.500" />
          <Text ml={4} fontSize="xl" color="gray.500">
            Loading coach feedback...
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default CoachFeedback;
