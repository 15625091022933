// Component to display AI-generated feedback
import React from 'react';
import { Box, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';

const AIFeedback = ({ data, period }) => {
  console.log(`Rendering AIFeedback for ${period}:`, data); // Log to ensure data is correct

  // Check if the correct AI analysis exists for the given period
  if (!data || !data.ai_analysis) {
    return <Text>No AI insights available for {period}</Text>;
  }

  // Splitting AI feedback into sections (if not already structured)
  const sections = data.ai_analysis.split('\n\n');

  return (
    <Box mt={4}>
      <Text fontWeight="bold">AI Insights ({period.charAt(0).toUpperCase() + period.slice(1)}):</Text>
      <Accordion allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Overview
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>{sections[0]}</Text>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Insights
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>{sections[1]}</Text>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Recommendations
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>{sections[2]}</Text>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                SMART Goals
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>{sections[3]}</Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default AIFeedback;
