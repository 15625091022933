import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { InlineWidget } from 'react-calendly';

const ScheduleCoaching = ({ calendlyUrl }) => {
  useEffect(() => {
    const handleEventScheduled = (e) => {
      if (e.data.event && e.data.event.indexOf('calendly.event_scheduled') === 0) {
        window.location.href = '/user-profile';
      }
    };

    window.addEventListener('message', handleEventScheduled);

    return () => {
      window.removeEventListener('message', handleEventScheduled);
    };
  }, []);

  return (
    <Box textAlign="center">
      <InlineWidget
        url={calendlyUrl}
        styles={{ height: '650px' }}
      />
    </Box>
  );
};

export default ScheduleCoaching;