import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export const usePerformanceData = () => {
  const [performanceData, setPerformanceData] = useState(null);
  const [aiAnalysis, setAiAnalysis] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSquareIntegrated, setIsSquareIntegrated] = useState(null);

  // Check if Square is integrated for the user
  const checkSquareIntegration = useCallback(async () => {
    try {
      const response = await axios.get('https://sbscoaches.com/api/user_info', {
        withCredentials: true,
      });
      console.log('Square integration check response:', response.data);
      const isConnected = response.data.is_connected_to_square;
      setIsSquareIntegrated(isConnected);
      console.log('Is connected to Square:', isConnected);
      return isConnected;
    } catch (error) {
      console.error('Error checking Square integration:', error);
      setIsSquareIntegrated(false);
      setError('Failed to check Square integration status.');
      return false;
    }
  }, []);

  // Fetch the performance data from the API for both weekly and monthly
  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const isIntegrated = await checkSquareIntegration();
      console.log('Is integrated with Square (in fetchData):', isIntegrated);
      if (isIntegrated) {
        const response = await axios.get('https://sbscoaches.com/api/square/performance', {
          withCredentials: true,
        });
        console.log("Fetched performance data:", response.data);
        
        if (response.data && response.data.performanceData && response.data.ai_analysis) {
          setPerformanceData(response.data.performanceData);
          setAiAnalysis(response.data.ai_analysis);
        } else {
          console.error('Invalid response structure:', response.data);
          setError('Invalid response structure from server');
        }
      } else {
        console.log('Square integration not found, setting appropriate states');
        setPerformanceData(null);
        setAiAnalysis(null);
        setError('Square integration not found');
      }
    } catch (err) {
      console.error('Error fetching performance data:', err);
      setError(err.message || 'Failed to fetch performance data. Please try again later.');
      setPerformanceData(null);
      setAiAnalysis(null);
    } finally {
      setLoading(false);
    }
  }, [checkSquareIntegration]);

  // Fetch the data on component mount
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Refresh the data
  const refreshData = useCallback(() => {
    fetchData();
  }, [fetchData]);

  return {
    performanceData,
    aiAnalysis,
    loading,
    error,
    refreshData,
    isSquareIntegrated,
    checkSquareIntegration,
  };
};
