import React, { useState } from 'react';
import {
  Box, Button, Input, FormControl, FormLabel, Heading, Text, VStack, useToast,
  Flex, Icon, Container, Select
} from '@chakra-ui/react';
import { FaUserPlus } from 'react-icons/fa';

const Signup = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('annual'); // Default to annual
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch('https://sbscoaches.com/auth/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
          name: `${firstName} ${lastName}`,
          subscription_type: subscriptionType,  // Send subscription type
        }),
        credentials: 'include'
      });
      const data = await response.json();
      if (response.ok) {
        window.location.href = data.checkout_url; // Redirect to the Stripe checkout session URL
      } else {
        setMessage(data.error || 'Failed to sign up. Please try again.');
        toast({
          title: 'Error',
          description: data.error || 'Failed to sign up. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      setMessage('Failed to sign up. Please try again.');
      toast({
        title: 'Error',
        description: 'Failed to sign up. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const displayPrice = subscriptionType === 'annual' ? '$65.00 per year' : '$7.00 per month';

  return (
    <Flex minHeight="100vh" width="full" align="center" justifyContent="center" bg="gray.50">
      <Container maxW="md" p={0}>
        <Box
          borderWidth={1}
          borderRadius="lg"
          p={8}
          boxShadow="lg"
          bg="white"
        >
          <VStack spacing={6} align="stretch">
            <Box textAlign="center">
              <Icon as={FaUserPlus} w={10} h={10} color="purple.500" mb={4} />
              <Heading mb={2} color="gray.800">Join Our Professional Community</Heading>
              <Text color="gray.600">
                Connect with fellow beauty experts. Elevate your career with resources, support, and inspiration designed for your success.
              </Text>
            </Box>

            <Box bg="purple.50" p={4} borderRadius="md">
              <Heading size="md" mb={2} color="purple.700">Subscription Plan</Heading>
              <Text fontSize="xl" fontWeight="bold" color="purple.600">{displayPrice}</Text>
              <Text color="gray.600">Includes access to all features and premium support.</Text>
              <Text fontSize="lg" mt={2} color="green.500">15-day free trial</Text>
              <Text fontSize="md" mt={2} color="blue.600">
                Complimentary business coaching session with Michael included (a $155 value)!
              </Text>
            </Box>

            <form onSubmit={handleSubmit}>
              <VStack spacing={4}>
                <FormControl id="firstName">
                  <FormLabel color="gray.700">First Name</FormLabel>
                  <Input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    borderColor="purple.200"
                    _hover={{ borderColor: 'purple.300' }}
                    _focus={{ borderColor: 'purple.500', boxShadow: '0 0 0 1px purple.500' }}
                  />
                </FormControl>
                <FormControl id="lastName">
                  <FormLabel color="gray.700">Last Name</FormLabel>
                  <Input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    borderColor="purple.200"
                    _hover={{ borderColor: 'purple.300' }}
                    _focus={{ borderColor: 'purple.500', boxShadow: '0 0 0 1px purple.500' }}
                  />
                </FormControl>
                <FormControl id="email">
                  <FormLabel color="gray.700">Email</FormLabel>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    borderColor="purple.200"
                    _hover={{ borderColor: 'purple.300' }}
                    _focus={{ borderColor: 'purple.500', boxShadow: '0 0 0 1px purple.500' }}
                  />
                </FormControl>
                <FormControl id="password">
                  <FormLabel color="gray.700">Password</FormLabel>
                  <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    borderColor="purple.200"
                    _hover={{ borderColor: 'purple.300' }}
                    _focus={{ borderColor: 'purple.500', boxShadow: '0 0 0 1px purple.500' }}
                  />
                </FormControl>

                <FormControl id="subscriptionType">
                  <FormLabel color="gray.700">Select Subscription Type</FormLabel>
                  <Select
                    value={subscriptionType}
                    onChange={(e) => setSubscriptionType(e.target.value)}
                    borderColor="purple.200"
                    _hover={{ borderColor: 'purple.300' }}
                    _focus={{ borderColor: 'purple.500', boxShadow: '0 0 0 1px purple.500' }}
                  >
                    <option value="annual">Annual - $65/year</option>
                    <option value="monthly">Monthly - $7/month</option>
                  </Select>
                </FormControl>

                <Button
                  type="submit"
                  colorScheme="purple"
                  width="full"
                  isLoading={isLoading}
                  loadingText="Joining..."
                  _hover={{ bg: 'blue.500' }}
                  transition="all 0.2s"
                >
                  {isLoading ? 'Joining...' : 'Start Free Trial'}
                </Button>
              </VStack>
            </form>
            {message && <Text mt={4} color="red.500" textAlign="center">{message}</Text>}
          </VStack>
        </Box>
      </Container>
    </Flex>
  );
};

export default Signup;
