import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Link as ChakraLink,
  InputGroup,
  InputLeftElement,
  Flex,
  Icon
} from '@chakra-ui/react';
import { EmailIcon, LockIcon, AtSignIcon } from '@chakra-ui/icons';
import { FaRobot } from 'react-icons/fa';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { login, user } = useAuth();
  console.log('Auth context user:', user);

  useEffect(() => {
    if (user) {
      const timer = setTimeout(() => {
        if (user.business_category) {
          navigate('/user-profile');
        } else {
          navigate('/welcome');
        }
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Email:', email);
    console.log('Password:', password);
    try {
      const result = await login({ email, password });
      console.log('Login result:', result);
      if (result.success) {
        setMessage('Welcome back, beauty professional!');
      } else {
        setMessage(result.error || 'Failed to log in. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setMessage(error.message || 'Failed to log in. Please try again.');
    }
  };

  const bgGradient = useColorModeValue(
    'linear(to-br, orange.300, cyan.400)',
    'linear(to-br, orange.500, cyan.500)'
  );
  const cardBg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const buttonColorScheme = 'cyan';

  return (
    <Box
      minHeight="100vh"
      width="100%"
      bgGradient={bgGradient}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box bg={cardBg} p={8} borderRadius="lg" boxShadow="xl" w="full" maxW="md" textAlign="center">
        <Flex justify="center" mb={6}>
          <Icon as={FaRobot} w={16} h={16} color="orange.500" />
        </Flex>
        <Heading mb={4} color={textColor} size="xl">Your AI Beauty Business Coach</Heading>
        <Text mb={6} color={textColor}>
          Reconnect with your community and continue to elevate your beauty career. We're here to support your growth and creativity.
        </Text>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <FormControl id="email">
              <FormLabel color={textColor}>Email</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <EmailIcon color="orange.500" />
                </InputLeftElement>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  bg="gray.100"
                  color="gray.800"
                  _hover={{ bg: 'gray.200' }}
                  _focus={{ borderColor: 'orange.500', boxShadow: '0 0 0 1px orange.500' }}
                />
              </InputGroup>
            </FormControl>
            <FormControl id="password">
              <FormLabel color={textColor}>Password</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <LockIcon color="orange.500" />
                </InputLeftElement>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  bg="gray.100"
                  color="gray.800"
                  _hover={{ bg: 'gray.200' }}
                  _focus={{ borderColor: 'orange.500', boxShadow: '0 0 0 1px orange.500' }}
                />
              </InputGroup>
            </FormControl>
            <Button
              type="submit"
              colorScheme={buttonColorScheme}
              width="full"
              leftIcon={<AtSignIcon />}
              _hover={{ bg: 'cyan.600' }}
            >
              Log In
            </Button>
            <Flex justify="space-between" width="100%">
              <ChakraLink as={Link} to="/forgot-password" color="orange.500" textDecoration="underline">
                Forgot Password?
              </ChakraLink>
              <ChakraLink as={Link} to="/contact" color="orange.500" textDecoration="underline">
                Contact Us
              </ChakraLink>
            </Flex>
          </VStack>
        </form>
        {message && <Text mt={4} color={textColor} fontWeight="bold">{message}</Text>}
      </Box>
    </Box>
  );
};

export default Login;
