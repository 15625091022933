import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  UnorderedList,
  ListItem,
  useColorModeValue,
  Divider,
} from '@chakra-ui/react';

const MySalonSuitesRaffleTerms = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const headingColor = useColorModeValue('blue.600', 'blue.300');
  const sectionBgColor = useColorModeValue('white', 'gray.700');
  const highlightColor = useColorModeValue('green.500', 'green.300');

  return (
    <Box bg={bgColor} minHeight="100vh" py={12}>
      <Container maxW="4xl">
        <VStack spacing={8} align="stretch">
          <Heading as="h1" size="2xl" textAlign="center" color={headingColor}>
            Raffle Terms and Conditions
          </Heading>

          <VStack spacing={6} align="stretch">
            <Box bg={sectionBgColor} p={6} borderRadius="md" boxShadow="md">
              <Heading as="h2" size="lg" mb={3} color={headingColor}>
                Techniculture 200 Club
              </Heading>
              <Text mb={4}>
                The first 200 subscribers will automatically become members of the exclusive Techniculture 200 Club, unlocking these premium benefits:
              </Text>
              <UnorderedList pl={5} spacing={2} color={highlightColor}>
                <ListItem>Early access to future premium AI features</ListItem>
                <ListItem>Exclusive webinars with industry experts</ListItem>
                <ListItem>Priority customer support access</ListItem>
                <ListItem>Opportunity to shape future AI developments through feedback sessions</ListItem>
              </UnorderedList>
            </Box>

            <Divider />

            {[
              {
                title: '1. Eligibility',
                content: 'This raffle is open to My Salon Suite Owners who have completed the 15-day free trial and paid for a one-year subscription to our AI service. Participants must be 18 years or older. Employees of SBS Coaches and their immediate family members are not eligible.'
              },
              {
                title: '2. Entry Period',
                content: 'The raffle entry period begins on August 1, 2024 and ends on October 31, 2024, at 11:59 PM PST.'
              },
              {
                title: '3. How to Enter',
                content: 'Eligible participants are automatically entered into the raffle upon completing the free trial and paying for a one-year subscription to our AI service within the entry period.'
              },
              {
                title: '4. Prizes',
                content: (
                  <UnorderedList pl={5}>
                    <ListItem>Up to $400 in industry credits from providers such as (Vistaprint, Saloncentric, My Salon Suites Marketplace)</ListItem>
                    <ListItem>One week of free rent (up to $250 value)</ListItem>
                    <ListItem>Various prizes from Apple and Nordstrom</ListItem>
                  </UnorderedList>
                )
              },
              {
                title: '5. Winner Selection and Notification',
                content: 'Winners will be selected randomly on November 30, 2024. Winners will be notified via email within 7 days of the drawing. If a winner does not respond within 14 days, we reserve the right to select an alternate winner.'
              },
              {
                title: '6. General Conditions',
                content: 'By participating, entrants agree to these official rules and the decisions of SBS Coaches, which are final and binding in all matters related to the raffle. Prizes are non-transferable and no substitution will be made except as provided herein at the Sponsor\'s sole discretion.'
              },
              {
                title: '7. Limitations of Liability',
                content: 'By participating, participants agree to release and hold harmless SBS Coaches and its subsidiaries, affiliates, advertising and promotion agencies, partners, representatives, agents, successors, assigns, employees, officers, and directors from any liability, illness, injury, death, loss, litigation, claim, or damage that may occur, directly or indirectly, whether caused by negligence or not, from participant\'s participation in the raffle and/or their acceptance, possession, use, or misuse of any prize or any portion thereof.'
              },
              {
                title: '8. Sponsor',
                content: 'The raffle is sponsored by SBS Coaches.'
              }
            ].map((section, index) => (
              <Box key={index} bg={sectionBgColor} p={6} borderRadius="md" boxShadow="md">
                <Heading as="h2" size="lg" mb={3} color={headingColor}>
                  {section.title}
                </Heading>
                {typeof section.content === 'string' ? (
                  <Text>{section.content}</Text>
                ) : (
                  section.content
                )}
              </Box>
            ))}
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};

export default MySalonSuitesRaffleTerms;
